.connexion-status {
    width: 30px;
    height: 30px;
    border-radius: 25px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.connexion-status.on {
    background-color: #42be6533;
    color: var(--vert-500);
}
.connexion-status.off {
    background-color: #ca1c2533;
    color: #ca1c25;
}
.connexion-status.warning {
    background-color: #ee961733;
    color: #ee9617;
}

.connexion-status .con-msg {
    display: block;
    position: absolute;
    top: 30px;
    padding: 10px;
    border-radius: 20px;
    font-weight: bold;
}
.connexion-status.on .con-msg {
    display: none;
}
.connexion-status.off .con-msg {
    background-color: #ca1c2533;
    color: #ca1c25;
    border: 1px solid #ca1c2588;
}
.connexion-status.warning .con-msg {
    background-color: #ee961733;
    color: #ee9617;
    border: 1px solid #ee961788;
}
@media /*(min-width: 320px) and */(max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .connexion-status .con-msg {
        display: none;
    }
}
