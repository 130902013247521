.game-header .plus-icon {
    color: var(--vert-500);
    height: 18;
}

.game-header .sold-amount {
    padding: 0 5px 0 9px;
}
.charge-btn-group {
    border: 1px solid var(--vert-500);
    border-radius: 16px;
    width: 65% !important;
    padding-left: 5px;
    padding-right: 5px;
}
.reload-btn {
    padding: 7px !important;
}
