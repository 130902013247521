/* PokerAnalysis.css */

/* Modal styles */
.m-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    z-index: 10000;
    transition:
        opacity 0.3s ease,
        visibility 0.3s ease;
}

.m-modal.open {
    opacity: 1;
    visibility: visible;
}

.m-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Overlay opacity */
}

.m-modal-content {
    position: relative;
    z-index: 2;
    background-color: rgba(66, 190, 101, 0.2) !important;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    width: 100%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--vert-500);
}
/* This class triggers the unfolding effect */
.m-modal-content.unfold {
    transform-origin: top; /* Set the transform origin to top */
    transform: scaleY(0); /* Start with scaleY at 0 */
    animation: unfold 0.5s forwards ease-in-out;
}
.m-modal .players {
    display: flex;
    justify-content: space-around;
}

.m-modal .player-column {
    text-align: center;
}

.m-modal .cards {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.m-modal .card {
    margin: 5px 0;
    font-size: 24px;
    opacity: 0;
    transform: translateX(-50px);
    animation: showCard 0.5s forwards ease-in;
}
.m-modal .card img {
    height: 70px;
}
.m-modal .animate {
    animation: showCard 0.5s forwards; /* Animation to show the card */
}

@keyframes showCard {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.m-modal .card {
    animation: showCard 0.5s forwards ease-in-out;
    opacity: 0;
    transform: translateY(20px);
}

.m-modal .close-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: right;
}
/* Unfolding animation keyframes */
@keyframes unfold {
    from {
        transform: scaleY(0);
    }
    to {
        transform: scaleY(1);
    }
}
.text-header {
    background-color: #fff2;
}
.cursor-pointer:hover {
    cursor: pointer;
}
.player-column h6.beginner {
    font-weight: bold;
    color: var(--yelow-500) !important;
}
.player-column h6 {
    padding: 0 10px;
}
.players {
    overflow-x: auto;
}
