:root {
    --borderWidth: 3px;
    --height: 12px;
    --width: 6px;
    --borderColor: #fff;
}

.checkbox-wrapper {
    width: 100%;
}
.checkbox-label {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
}
.checkbox-label label {
    width: calc(100% - 1.6em);
}
.checkbox-wrapper input[type='checkbox'] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.6em;
    height: 1.6em;
    border-radius: 0.15em;
    margin-right: 0.5em;
    border: 0.15em solid #007a7e;
    outline: none;
    cursor: pointer;
}
input:checked {
    background-color: #007a7e;
    position: relative;
}

input:checked::before {
    content: '';
    font-size: 1.5em;
    color: #fff;
    position: absolute;
    right: 5px;
    top: 2px;
    display: inline-block;
    transform: rotate(45deg);
    height: var(--height);
    width: var(--width);
    border-bottom: var(--borderWidth) solid var(--borderColor);
    border-right: var(--borderWidth) solid var(--borderColor);
}

input.checked::before {
    content: '2714';
    font-size: 1.5em;
    color: #fff;
    position: absolute;
    right: 1px;
    top: -5px;
}

.checkbox-wrapper input[type='checkbox']:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
}

.checkbox-wrapper input[type='checkbox']:disabled + span {
    color: #c0c0c0;
}

.checkbox-wrapper input[type='checkbox']:focus {
    box-shadow: 0 0 20px #007a7e;
}
.black-theme.checkbox-wrapper input[type='checkbox'] {
    border: 0.15em solid #007a7e;
    background-color: var(--dark);
}
.black-theme.checkbox-wrapper input[type='checkbox']:focus {
    box-shadow: none;
}
.black-theme.checkbox-wrapper input[type='checkbox']:disabled + span {
    color: var(--vert-500);
}

.black-theme input:checked::before {
    color: #fff;
    border-bottom: var(--borderWidth) solid var(--vert-500);
    border-right: var(--borderWidth) solid var(--vert-500);
}

.black-theme input.checked::before {
    color: #fff;
}

.black-theme .usage-condition {
    color: #fff;
    font-weight: 300;
}
