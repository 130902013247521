.term-policy {
    display: none;
}
.term-policy.active {
    display: block;
}
.spinner-container {
    text-align: center;
    padding: 30px 0;
}
.spinner-container svg {
    height: 100px;
}
.term-policy-link {
    border-bottom: 2px solid #eee;
}
.term-policy-link h5 {
    color: #fff;
    padding: 10px 0;
    font-size: 15px;
    text-align: center;
}
.term-policy-link h5:hover {
    cursor: pointer;
}
.term-policy-link.active {
    border-bottom: 2px solid var(--vert-600);
}
.term-policy-link.active h5 {
    color: var(--vert-600);
}
.oups {
    text-align: center;
    margin-top: 50px;
    color: #eee;
    font-weight: 800;
}
.terms-policy-container {
    color: #fff;
}
.terms-policy-container p {
    margin-bottom: 10px !important;
    margin-left: 0 !important;
}
