@keyframes spinners-round-filled-outer {
    40%,
    60% {
        transform: scale(0);
    }
}

@keyframes spinners-round-filled-center {
    30%,
    70% {
        transform: scale(0);
    }
}

@keyframes spinners-round-filled-inner {
    20%,
    80% {
        transform: scale(0);
    }
}
