.next-card .icon-card {
    margin-top: -16px;
    padding-bottom: 16px;
}
.next-card .icon-card img {
    border: 4px solid #ffaf3344;
    border-radius: 6px;
}
.next-card {
    position: relative;
}
.next-card .icon-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    border-radius: 8px;
    border: 2px solid var(--yelow-500);
    box-sizing: border-box;
    animation: wave 1.5s ease-out infinite;
    pointer-events: none;
    margin-top: -16px;
    padding-bottom: 16px;
    margin-left: -24px;
}
@keyframes wave {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}
.current-player {
    width: auto !important;
    height: auto !important;
    border-radius: inherit !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
}
.current-player .player-pseudo {
    padding: 4px 8px;
    background-color: var(--vert-opaced);
    border-radius: 16px;
    font-size: 10px;
}

.current-player img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
