.leadboard-player-infos img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}
.leadboard-player-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    width: 100%;
    padding: 15px 10px;
    border-left: 2px solid var(--vert-500);
    border-radius: 10px;
}

.leadboard-player-infos .number {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--opaced-bkg);
    display: flex;
    justify-content: center;
    align-items: center;
}
.payer-infos-score {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.player-separator {
    flex-grow: 1; /* Take up remaining space */
    border-bottom: 2px dashed var(--vert-500); /* Dashed line */
    margin: 0 20px; /* Space around the dashed line */
    height: 0; /* Height doesn't matter for horizontal line */
}
.payer-infos-score .score {
    font-size: 16px;
    font-weight: bold;
    color: var(--vert-500);
}
.leaderboard-list {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.leadboard-player-infos .user-name {
    font-size: 15px;
    font-weight: bold;
}

@media (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .list-grid {
        grid-template-columns: 1fr !important;
    }
}
