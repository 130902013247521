.top-header .language {
    border-radius: 10px;
    padding: 0 5px !important;
    border: 1px solid transparent;
}
.top-header .language:hover {
    cursor: pointer;
    background-color: var(--vert-500) !important;
    color: #fff !important;
    border-radius: 10px;
    border: 1px solid var(--vert-500);
}
.top-header .language.active {
    /*background-color: #eee !important;*/
    border-radius: 10px;
    color: var(--vert-500);
    padding: 0 5px;
    border: 1px solid var(--vert-500);
}
