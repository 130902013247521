.player-side {
    width: 100%;
    position: relative;
    align-items: center;
}
.icon-button {
    color: white;
    cursor: pointer;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submenu {
    position: absolute;
    top: 40px; /* Adjust based on your design */
    left: 0;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1;
}

.submenu-button {
    position: absolute;
    bottom: 40px; /* Adjust to position above the button */
    right: 0;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1;
}

.submenu-button:hover {
    background-color: #ddd;
}

.m-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal content */
.m-modal-overlay .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.m-modal-overlay .modal-button {
    display: block;
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.m-modal-overlay .modal-button:hover {
    background-color: #0056b3;
}

.m-modal-overlay .modal-close {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.m-modal-overlay .icon-button {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.m-modal-overlay .icon-button:hover {
    background-color: #0056b3;
}

.player-block {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
}
.player-block .cards {
    flex-grow: 1;
    justify-content: center;
}
@media /*(min-width: 320px) and */(max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .player-side {
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    .game-side {
        padding-bottom: 0;
    }
    .active-player .user-datas {
        justify-content: center;
        align-items: center;
    }
    .player-side {
        justify-content: space-between !important;
    }
    .width-auto {
        width: auto !important;
    }
    .player-actions {
        display: flex;
        justify-content: end;
    }
    .player-side .cards {
        justify-content: center;
        width: 100%;
    }
}
