.contact-btn {
    display: inline-block !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
}

@media (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .contact-btn {
        display: block !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        width: 50%;
    }
}
