@media /*(min-width: 320px) and */(max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .table-space .table-top {
        display: flex;
        justify-content: center !important;
        align-items: center;
    }
    .table-space {
        margin-bottom: 15px;
    }
    .quit-btn {
        width: 40px;
        height: 40px;
        border-radius: 40px !important;
        padding: 0 !important;
    }
}
.table-page-top .user-datas {
    width: auto !important;
}
.table-page-top .top-menu {
    width: 100% !important;
}
