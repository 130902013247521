button div {
}
.button {
    height: 42px;
    max-height: 42px;
    min-height: 42px;
}
.button .icon {
    height: inherit;
    width: inherit;
}
.button.btn-sm {
    height: 21px;
    max-height: 21px;
    min-height: 21px;
    font-size: 12px;
}
.button.disabled {
    opacity: 0.5;
}
