.private-party-lock svg {
    color: #fff;
}
.public-party-unlock svg {
    color: #fff;
}
.game-card {
    animation: 100ms linear;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
}

.game-card:hover {
    border: 1px solid var(--vert-500);
    cursor: pointer;
    animation: borderExpand 0.5s forwards;
}
.game-card.is-competition {
    background: var(--linear-orange2) !important;
}
.game-card.is-competition:hover {
    border: 1px solid #ee9617 !important;
}
.game-card.blocked-user {
    background: var(--linear-pink) !important;
}
.game-card.blocked-user:hover {
    border: 1px solid #ffc7c7 !important;
}
.game-card.cannot-join {
    background: var(--linear-gray) !important;
}
.game-card.cannot-join:hover {
    border: 1px solid #eee !important;
}
.special-mode-icon {
    color: #808080 !important;
    height: 25px !important;
    width: 25px !important;
}

.special-mode-icon svg {
    color: gray !important;
    height: 25px !important;
    width: 25px !important;
    color: #fff !important;
}
.party-amount {
    display: inline-block;
    background-color: var(--yelow-500);
    color: #000;
    border-radius: 10px;
    font-weight: 600;
    padding: 2px 10px;
}
.party-pseudo {
    font-weight: bold;
}
.widget.special-widget {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.widget-body {
    display: flex;
    gap: 20px;
}
.game-card {
    padding: 18px !important;
}
