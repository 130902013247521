.player.player-top .user-prizes .user-avatar {
    order: 2;
}
.player.player-top .user-prizes .victories {
    order: 1;
}
.player.player-top .user-prizes .defeats {
    order: 3;
}
.player.player-bottom .user-avatar {
    border: none !important;
}
.resigned {
    filter: grayscale(100%);
}

.player-hand .played-cards .card {
    box-shadow:
        -5px 0px 10px rgba(0, 0, 0, 0.1),
        5px 0px 10px rgba(0, 0, 0, 0.1);
    border: none !important;
}
.player.player-top .player-hand .played-cards {
    flex-direction: row !important;
}
.player.player-bottom .player-hand .played-cards {
    flex-direction: row !important;
}
@media /*(min-width: 320px) and */(max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .player.player-left {
        width: auto;
    }

    .player.player-right {
        width: auto;
    }

    /*.player.player-left .user-avatar,
    .player.player-right .user-avatar {
        display: flex;
        flex-direction: column;
    }
    .player.player-left .played-cards,
    .player.player-right .played-cards {
        flex-direction: column;
    }

    .player.player-left .player-hand .played-cards .card,
    .player.player-right .player-hand .played-cards .card {
        margin-top: -40px;
    }

    .player.player-left .player-hand .played-cards .card:first-child,
    .player.player-right .player-hand .played-cards .card:first-child {
        margin-top: auto;
    }

    .player.player-left .player-hand {
        right: 0;
        top: 60px;
    }
    .player.player-left .player-hand .played-cards {
        left: 15px;
        top: 70px;
    }

    .player.player-right .player-hand {
        right: 0;
        top: 60px;
    }
    .player.player-right .player-hand .played-cards {
        right: 10px;
        top: 70px;
    }
    .empty-card {
        display: none;
    }*/

    .player.player-right .player-hand .played-cards {
        flex-direction: row;
    }
    .player.player-left .player-hand .played-cards .card {
        margin-left: -30px;
    }
    .player.player-right .player-hand .played-cards .card {
        margin-left: -30px;
    }
    .empty-card {
        display: none;
    }

    .player.player-left .player-id {
        transform: translate(-50%, -50%) rotate(90deg);
        top: 60px;
        position: relative;
    }

    .player.player-right .player-id {
        transform: translate(-50%, -50%) rotate(-90deg);
        top: 60px;
        position: relative;
        left: 80px;
    }
    .player.player-left .player-hand .player-tour {
        position: relative;
    }
    .player.player-left .player-hand .player-tour:first-child {
        left: -75px;
        top: -40px;
    }
    .player.player-left .player-hand .player-tour:last-child {
        top: 50px;
        left: -104px;
    }

    .player.player-right .player-hand .player-tour {
        position: relative;
    }
    .player.player-right .player-hand .player-tour:first-child {
        right: -100px;
        top: -40px;
    }
    .player.player-right .player-hand .player-tour:last-child {
        top: 50px;
        right: -72px;
    }
}
