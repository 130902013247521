.icon {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: center;
}

.icon8,
.icon8 svg {
  width: 8px;
  height: 8px;
}

.icon12,
.icon12 svg {
  width: 12px;
  height: 12px;
}

.icon16,
.icon16 svg {
  width: 16px;
  height: 16px;
}


.icon24,
.icon24 svg {
  /* width: 24px; */
  height: 24px;
}

.icon32,
.icon32 svg {
  width: 32px;
  height: 32px;
}

.icon48,
.icon48 svg {
  width: 48px;
  height: 48px;
}

.icon56,
.icon56 svg {
  width: 56px;
  height: 56px;
}

/* .icon-card{
  width: ;
} */




.logo {
  width: 48px;
  height: 48px;

  /* Inside auto layout */

  background-image: url(./fapfap-logo.svg);
  background-size: contain;
}

.download-android,
.download-apple {
  /* Inside auto layout */

}

.download-apple {
  width: 148px;
  height: 56px;
  background-image: url(./iPhone\ Os.svg);
  background-size: contain;

}

.download-android {
  width: 122.06px;
  height: 56px;
  background-image: url(./google-play-4\ 1.svg);
  background-size: contain;
}

.icon.user-check {
  background-image: url(./user-check.svg);
  background-size: contain;
}

.icon.get-in {
  background-image: url(./get-in.svg);
  background-size: contain;
}

.icon.assistance {
  background-image: url(./assistance.svg);
  background-size: contain;
}

.icon.trophee-star {
  background-image: url(./trophee-star.svg);
  background-size: contain;
}

.icon.arrow-right {
  background-image: url(./arrow-right.svg);
  background-size: contain;
}

.icon.arrow-left {
  background-image: url(./arrow-left.svg);
  background-size: contain;
}

.icon.arrow-left {
  background-image: url(./arrow-left.svg);
  background-size: contain;
}

.icon.classement-up {
  background-image: url(./classement-up.svg);
  background-size: contain;
}

.icon.classement-up {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.32688 7.58499L5.15087 2.52813C5.38668 2.19678 5.76823 2 6.17492 2C6.58161 2 6.96317 2.19678 7.19897 2.52813L11.3792 7.58499C11.7215 8.01278 11.8015 8.5939 11.5875 9.09827C11.3735 9.60263 10.9 9.94891 10.3545 10H2.34892C1.8039 9.94803 1.33125 9.60142 1.11787 9.09721C0.904486 8.59301 0.984734 8.01241 1.32688 7.58499Z' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-size: contain;
}