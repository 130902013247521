.video-responsive {
    width: 100%;
}

.video-responsive iframe {
    height: 500px;
    width: 100%;
}

@media (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .video-responsive iframe {
        height: 200px;
    }
}
