.number-country {
    display: flex;
    width: 100%;
    background: #ffffff;
    border: 1px solid var(--gris-200);
    border-radius: 16px;
    height: -moz-fit-content;
    height: fit-content;
    align-items: center;
}
.number-country.focused {
    outline: 1px solid var(--vert-500);
    border: 1px solid var(--vert-500);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}
.number-country input {
    padding: 12px;
    background: #ffffff;
    border: 1px solid var(--gris-200);
    border-radius: 16px;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
}
.number-country .css-dczrug-control {
    border: none;
}
.number-country input {
    border: none;
}
.number-country input:focus,
.number-country input:hover {
    outline: none;
    border: none;
    box-shadow: none;
}
.css-dczrug-control:hover {
    border: none;
}
.css-18qp85y-control {
    border: none !important;
    box-shadow: none;
}
.css-18qp85y-control:hover {
    border: none !important;
    box-shadow: none;
}
.css-1ecm5ky-control {
    border: none !important;
    box-shadow: none !important;
}
.css-1dimb5e-singleValue .country-name {
    display: none;
}
.black-theme .number-country {
    background: var(--opaced-bkg);
}
.black-theme .number-country input {
    background: transparent;
    color: #fff;
}
.black-theme .phone_number__control {
    background-color: transparent;
    color: #fff;
}
.black-theme .phone_number__control--is-focused {
    background-color: var(--vert-500);
    color: #fff;
}
.black-theme .phone_number__control * {
    color: #fff;
}
.black-theme .phone_number__control * {
    color: #fff;
}
.black-theme .phone_number__menu {
    background-color: var(--dark);
    color: #fff;
}

.black-theme .phone_number__option:hover {
    background-color: var(--opaced-bkg);
}

.black-theme .phone_number__option--is-focused {
    background-color: var(--opaced-bkg);
}

.black-theme .phone_number__option--is-selected {
    background-color: var(--vert-600);
}
