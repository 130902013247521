.page-not-found-container svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -400px;
}
.page-not-found-container .message-box {
    height: 200px;
    width: 380px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: 50px;
    color: #fff;
    font-family: Roboto;
    font-weight: 300;
}
.page-not-found-container .message-box h1 {
    font-size: 60px;
    line-height: 46px;
    margin-bottom: 40px;
}
.page-not-found-container .buttons-con .action-link-wrap {
    margin-top: 40px;
}
.page-not-found-container .buttons-con .action-link-wrap a {
    background: #68c950;
    padding: 8px 25px;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    transition: all 0.3s linear;
    cursor: pointer;
    text-decoration: none;
    margin-right: 10px;
}
.page-not-found-container .buttons-con .action-link-wrap a:hover {
    background: #5a5c6c;
    color: #fff;
}

.page-not-found-container #Polygon-1,
.page-not-found-container #Polygon-2,
.page-not-found-container #Polygon-3,
.page-not-found-container #Polygon-4,
.page-not-found-container #Polygon-4,
.page-not-found-container #Polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
}
.page-not-found-container #Polygon-2 {
    animation-delay: 0.2s;
}
.page-not-found-container #Polygon-3 {
    animation-delay: 0.4s;
}
.page-not-found-container #Polygon-4 {
    animation-delay: 0.6s;
}
.page-not-found-container #Polygon-5 {
    animation-delay: 0.8s;
}

@keyframes float {
    100% {
        transform: translateY(20px);
    }
}
@media (max-width: 450px) {
    .page-not-found-container svg {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -250px;
        margin-left: -190px;
    }
    .page-not-found-container .message-box {
        top: 50%;
        left: 50%;
        margin-top: -100px;
        margin-left: -190px;
        text-align: center;
    }
}
