.tab {
    display: none;
}
.tab.active {
    display: block;
}
.spinner-container {
    text-align: center;
    padding: 30px 0;
}
.spinner-container svg {
    height: 100px;
}
.tab-link {
    border-bottom: 2px solid #eee;
}
.tab-link h5 {
    color: #fff;
    padding: 10px 0;
    font-size: 15px;
    text-align: center;
}
.tab-link h5:hover {
    cursor: pointer;
}
.tab-link.active {
    border-bottom: 2px solid var(--vert-600);
}
.tab-link.active h5 {
    color: var(--vert-600);
}
.oups {
    text-align: center;
    margin-top: 50px;
    color: #eee;
    font-weight: 800;
}
.tab-container {
    min-height: 180px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tips img {
    height: 20px;
}
.tab .document-side a {
    color: var(--vert-600);
}
.tab .document-side a:hover {
    opacity: 0.8;
}
