@import url('./bootstrap.css');
@import url('./global.css');
@import url('../icons/icons.css');
@import url('./flags.css');
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 100px;
}

*::-webkit-scrollbar-thumb {
    background: linear-gradient(153.06deg, #26f596 -0.31%, #0499f2 99.69%);
    border-radius: 100px;
}

body {
    line-height: 1.5;
    font-size: 12px;
    color: #000000;
    background: #e8e5de;
}

/*
     Topbar side
 */

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

/* Hide on scroll */
#topbar {
    -webkit-transition: all 0.41s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.41s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

#topbar.blurred {
    -webkit-transition: all 0.41s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.41s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.page-dark #topbar.blurred {
    backdrop-filter: blur(24px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), rgba(255, 255, 255, 0.15);
}
#topbar {
    padding-left: 20px;
}
.top-bar {
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-bar .logo-side {
    display: flex;
    height: fit-content;
    width: fit-content;
}

.top-bar .nav-link {
    display: flex;
    gap: 8px;
}

.top-bar .nav-link li {
    padding: 16px 8px;
}

.top-bar .nav-link a {
    font-size: 12px;
}

.app-link {
    display: flex;
    flex-direction: row;
}

/*
     End Topbar side
 */

/*
   Hero introduction
 */

.hero-intro {
    height: 100vh;
    min-height: 700px;
    padding-top: 128px;
}

.intro-content {
    grid-column: 3 / 11;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.intro-content .intro-title,
.intro-content .intro-text {
    text-align: center;
    margin-top: 12px;
}

.intro-content .intro-title {
    color: var(--vert-500);
    font-weight: 700;
}

.intro-content .intro-text {
    font-size: 16px;
}

.intro-cta {
    align-self: center;
    margin-top: 24px;
}

.intro-scene {
    position: absolute;
    width: 100%;
    height: 100%;
    /* border: 1px solid yellow; */
    top: 0;
    left: 0;
    background-image: url('../scene/scene-background.png');
    background-size: cover;
    background-position: center bottom;
    z-index: -1;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
}

.scene-table {
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.empty-table {
    background-image: url('../scene/green-empty-table-2x.png');
    background-size: cover;
    width: 500px;
    height: 280px;
    position: relative;
}

.cube-element {
    position: absolute;
    width: 80px;
    height: 80px;
    left: calc(80% - 80px / 2);
    top: calc(50% - 80px / 2);
    background-image: url('../scene/cube.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.card-element {
    position: absolute;
    width: 80px;
    height: 80px;
    left: calc(20% - 80px / 2);
    top: calc(60% - 80px / 2);
    background-image: url('../scene/carte.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.pieces-element {
    position: absolute;
    width: 80px;
    height: 80px;
    left: calc(26% - 80px / 2);
    top: calc(79% - 80px / 2);
    background-image: url(../scene/pieces.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.table-playboy-bottom-right {
    position: absolute;
    bottom: -60px;
    right: -40px;
    width: 120px;
    height: 120px;
    background-image: url('../scene/table-playboy-bottom-right.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.table-playboy-bottom-left {
    position: absolute;
    bottom: -60px;
    left: -40px;
    width: 120px;
    height: 120px;
    background-image: url('../scene/table-playboy-bottom-left.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.table-playboy-human-bottom-center {
    position: absolute;
    bottom: -60px;
    left: calc(50% - 80px);
    width: 160px;
    height: 160px;
    background-image: url('../scene/table-playboy-human-bottom-center.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.table-playgirl-top-right {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 100px;
    height: 100px;
    background-image: url('../scene/table-playgirl-top-right.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.table-playboy-top-left {
    position: absolute;
    top: -20px;
    left: -20px;
    width: 100px;
    height: 100px;
    background-image: url('../scene/table-playboy-top-left.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.table-scene-biscuit {
    position: absolute;
    top: calc(30% - 50px);
    left: 40px;
    width: 40px;
    height: 40px;
    background-image: url('../scene/table-scene-biscuit.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.table-scene-heart {
    position: absolute;
    top: calc(50% - 50px);
    right: -20px;
    width: 70px;
    height: 70px;
    background-image: url('../scene/table-scene-heart.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.message-type-element {
    position: absolute;
    top: calc(50% - 80px);
    left: calc(50% - 80px);
    width: 180px;
    height: 180px;
    background-image: url('../scene/double-korat.png');
    background-size: contain;
    background-repeat: no-repeat;
}

/* End Intro scene */

/*
      Floating card : ideal pour les notification et les messages sur la page d'acceuil
  */

.floating-card {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    position: fixed;
    width: 230px;
    height: fit-content;
    left: 64px;
    bottom: 32px;
    z-index: 1000;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(24px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 24px;
    -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.floating-card .card-title {
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.floating-card p {
}

.emp {
    text-decoration: underline;
    font-weight: bolder;
}

.activity-content .players-on-line {
    width: fit-content;
    margin-top: 44px;
    right: 0;
    top: 0;
    padding-left: 96px;
    align-self: end;
}

.players-on-line {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 12px;

    position: fixed;
    width: 194px;
    height: fit-content;
    right: 64px;
    bottom: 32px;
    z-index: 998;

    /* Gold */

    background: linear-gradient(153.06deg, #ffdd00 -0.31%, #fbb034 99.69%);
    border-radius: 24px 24px 24px 56px;
    border: 4px solid #db3445;

    -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

/* L'effet Scale down ne s'obtient que sur tablette et plus */
.players-on-line.scale-down {
    animation: scale-down-right 0.4s;
    animation-fill-mode: forwards;
}

.players-on-line.scale-up {
    animation: scale-down-up 0.4s;
    animation-fill-mode: forwards;
}

@keyframes scale-down-right {
    0% {
        transform: scale(1);
        transform-origin: right center;
        -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    }

    100% {
        transform: scale(0.5);
        transform-origin: right center;
        -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    }
}

@keyframes scale-down-up {
    0% {
        transform: scale(0.5);
        transform-origin: right center;
        -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    }

    100% {
        transform: scale(1);
        transform-origin: right center;
        -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    }
}

.players-on-line .players,
.players-on-line .label {
    text-align: right;
    color: var(--gris-900);
    width: 100%;
    font-size: 12px;
}

.players-on-line .players {
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
}

.illustration {
    background-image: url(../illustrations/mini-table-player.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 118.38px;
    height: 90.59px;
    left: -24.19px;
    top: -46px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;
}

/*
   End Floating card
*/

/*
   Download app link
*/

.download-link {
    grid-column: 5 / 9;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
}

.download-link h6 {
    text-align: center;
}

.download-link ul {
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.download-link ul li {
    height: 56px;
    min-width: fit-content;
}

.download-link ul li a {
    display: block;
}

/*
   End Download app link
*/

/*
   Live data app link 
*/

.live-data {
}

.section-header {
    grid-column: 3 / 11;
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.section-header .header-hint {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    width: fit-content;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.18em;
    text-transform: uppercase;

    color: #ffffff;
}

.section-header h2 {
    text-align: center;
    width: 100%;
    line-height: 1.25;
    font-size: 32px;
    font-weight: 600;
    color: var(--vert-500);
}

.section-header p {
    font-size: 14px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 0;
}

.top-korateur {
    grid-column: span 8;
}

.top-section {
    grid-column: span 4;
}

.widget-group {
    position: relative;
    border-radius: 32px;
    padding: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.activity-content .widget-group {
    padding: 0;
}

.widget-paginated {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 24px;
}

.pagination .paginate-count:first {
    background-color: var(--vert-500);
}

.pagination .paginate-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px;
    gap: 10px;
    border-radius: 16px;
}

.pagination .paginate-control .left,
.paginate-control .right {
    width: 24px;
    height: 24px;
}

.group-header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 24px;
}

.group-header .group-title {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    margin: 0;
    color: var(--vert-500);
}

.group-header h5.group-title {
    font-size: 16px;
    color: white;
    font-weight: 400;
}

.group-header h4.group-title {
    font-size: 16px;
    color: white;
    font-weight: 500;
}

.group-header p {
    opacity: 0.7;
    width: 70%;
}

.group-sub-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.group-sub-title h4 {
    margin: 0;
}

.card-illustration.trophee-podium {
    position: absolute;
    width: 190px;
    height: 154px;
    top: -77px;
    right: -20px;

    transform: rotate(22.03deg);

    background-image: url(../illustrations/trophee-podium.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.date-navigator {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: fit-content;
    align-items: center;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 16px;
}

.date-navigator .left {
    padding: 12px;
}

.date-navigator .right {
    padding: 12px;
}

.date-navigator .date {
    font-weight: 500;
}

.group-container {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.group-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    overflow: auto;
    width: 100%;
    padding-bottom: 16px;
}

.podium {
    flex: 3;
}

.flex-four {
    flex: 4;
}

.flex-two {
    flex: 2;
}

.classement {
    flex: 2;
}

.group-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    gap: 8px;
    width: 100%;
    padding: 12px;

    background: linear-gradient(153.06deg, rgba(207, 214, 230, 0.05) -0.31%, rgba(231, 239, 249, 0.05) 99.69%);
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
}

.group-list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    margin-bottom: 16px;
}

.classement .group-list {
    padding: 16px;
    gap: 12px;
}

.group-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    padding: 12px;
}

.classement .group-list-item {
    padding: 0;
}

.separator {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    width: 100%;
    padding: 0;
    margin: 0;
}

.separator.light {
    border-bottom: 1px solid var(--gris-50);
    width: 100%;
    padding: 0;
    margin: 0;
}

/* Item separator */

.podium .group-list-item:nth-child(1) {
    background: linear-gradient(153.06deg, #ffdd00 -0.31%, #fbb034 99.69%);
    border-radius: 16px;
    color: var(--gris-950) !important;
}

.podium .group-list-item:nth-child(1) {
    color: var(--gris-950) !important;
}

.podium .group-list-item:nth-child(1) .statistics .points .icon svg {
    stroke: var(--gris-950) !important;
}

.group-list-item .statistics .points .icon svg {
    fill: none;
    stroke: white;
    /* border: red 1px solid; */
    display: block;
}

.group-list-item .rank {
    width: 4px;
    font-size: 8px;
}

.podium .group-list-item .rank {
    width: 7px;
}

.group-list-item .user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: fit-content;
}

.group-list-item .user-info .user-avatar {
    overflow: hidden;
    cursor: pointer;
    width: 56px;
    height: 56px;
    background: var(--gris-400);
    border-radius: 56px;
}

.classement .group-list-item .user-info .user-avatar {
    overflow: hidden;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.user-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.group-list-item .user-info .user-avatar.online {
    border: 4px solid var(--vert-500);
}

.classement .group-list-item .user-info .user-avatar.online {
    border: 2px solid var(--vert-500);
}

.playing {
    /* padding: 0.6em 2em; */
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.playing:before {
    content: '';
    background: linear-gradient(45deg, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    /* filter: blur(5px);
   -webkit-filter: blur(5px); */
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 30px;
}

@keyframes glowing-button-85 {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.playing:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--gris-400);
    left: 0;
    top: 0;
    border-radius: 56px;
}

.group-list-item .user-info .user-name {
    width: fit-content;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
}

.classement .group-list-item .user-info .user-name {
    font-size: 10px;
    font-weight: 400;
}

.group-list-item .statistics {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    width: fit-content;
}

.group-list-item .statistics .points {
    display: flex;
    flex-direction: row;
    gap: 0;
    align-items: center;
    font-size: 10px;
    font-weight: normal;
}

.classement .group-list-item .statistics .points {
    color: var(--gris-400);
}

.classement .group-list-item .statistics .points .icon svg {
    fill: none;
    stroke: var(--gris-400);
}

.group-list-item .statistics .bonus {
    font-size: 18px;
    font-family: var(--font-numeric);
    font-weight: 400;
}

.currency {
    font-size: 60%;
}

/*
   Me style for the current user info
*/

.classement .group-list-item.me .statistics .points {
    color: var(--vert-500);
}

.classement .group-list-item.me .statistics .points .icon svg {
    fill: none;
    stroke: var(--vert-500);
}

.classement .group-list-item.me .user-info .user-name {
    font-size: 12px;
    font-weight: 500;
    color: var(--vert-500);
}

.justify-space-between {
    justify-content: space-between;
}

.flex-auto {
    flex: auto;
}

/* end Live data */

/* Widget */

.widget-group-column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
}

.widget {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 32px;
    width: 100%;
    /* background: rgba(255, 255, 255, 0.05);
   box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(54px); */
    border-radius: 24px;
}

.widget.dyk {
    flex-direction: row;
    gap: 16px;
}

.widget-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.widget-header .widget-label {
    display: flex;
    align-items: center;
    gap: 16px;
}

.widget-header .widget-label .icon svg {
    width: 24px;
    height: 24px;
}

.widget-header .widget-label .widget-name {
}

.widget-header .widget-link {
    font-weight: 300;
    font-size: 8px;
    line-height: 1;
    padding: 0;
    margin: 0;
    display: block;
    float: left;
    color: var(--gris-400);
}

.widget-content {
}

.widget-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    gap: 12px;
    flex-direction: column;
}

.widget-list .user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: fit-content;
}

.widget-list .widget-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.widget-list .widget-list-item .user-info .user-avatar {
    overflow: hidden;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: var(--gris-400);
    border-radius: 24px;
}

.widget-list .widget-list-item .user-info .user-name {
}

.game-infos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.game-infos .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.game-infos .info span.icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.game-infos .info span.value {
    font-family: var(--font-numeric);
    color: var(--gris-400);
}

.join-infos {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 12px;
}

.actions button {
    flex: auto;
}

/*
      info-card card : ideal pour les notification et les messages sur la page d'acceuil
  */

.info-card {
    /* Auto layout */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 16px;

    position: relative;
    width: 100%;
    height: fit-content;
    border-radius: 24px;
}

.info-card.large {
    gap: 24px;
}

.info-card .card-title {
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.info-card-with-background {
    padding: 80px 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../images/officials/Rectangle\ 53.png);
    background-size: cover;
    border-radius: 32px;
}

.info-card-with-background .info-card {
    display: flex;
    align-items: center;
    text-align: center;
    max-width: 500px;
}

.info-card .card-title .icon svg {
    height: 24px !important;
    width: auto;
}

.info-card .card-name {
    font-size: 18px;
}

.info-card.illustrated p {
    width: calc(100% - (84px * 2 / 3) - 4px);
}

.info-card p {
    width: 96%;
    margin: 0;
    line-height: 1.6;
    opacity: 0.7;
    font-size: 14px;
}

.info-card.dyk p {
    font-size: 12px;
}

.invitations-list,
.participant-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
}

.participant-list .participants-name {
    color: var(--opaced-text);
}

.invitations-list .users,
.participant-list .users {
    display: flex;
}

.invitations-list .users .user-avatar,
.participant-list .users .user-avatar {
    overflow: hidden;
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-right: -8px;
    background-color: var(--vert-50);
    border-radius: 12px;
    border: 1px solid var(--gris-100);
}

.policy-card .card-illustration {
    position: absolute;
    width: 84px;
    height: 96px;
    right: -12px;
    bottom: 12px;
    background-image: url(../illustrations/policy-card-illustration.png);
    background-size: cover;
}

.participate-game-card .card-illustration {
    position: absolute;
    width: 220px;
    height: 128px;
    right: 0;
    bottom: 0;
    background-image: url(../illustrations/participate-card.png);
    background-size: cover;
}

.cta-btn {
    margin: 0;
    background: linear-gradient(153.06deg, #f6ebe6 -0.31%, #aee1f9 99.69%) !important;
    color: var(--gris-500) !important;
    font-weight: 400;
    justify-content: space-between;
    align-items: center;
}

.cta-btn .icon {
    stroke: var(--gris-500) !important;
}

/*
  info-panel
*/

.info-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;
    grid-column: 3/11;
    text-align: center;
}

.info-panel h2 {
    text-align: center;
    width: 100%;
    line-height: 1.25;
    font-size: 32px;
    font-weight: 600;
}

.info-panel p {
    font-size: 14px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 0;
}

.white-cta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 16px;
    /* custom button */
    width: 170px;
    text-align: center;
    justify-content: center;
    background: linear-gradient(153.06deg, #eeeeee -0.31%, #c2cbdd 99.69%) !important;
    color: var(--gris-500) !important;
    border-radius: 16px;
}

/* Event Card */

.home.horizontal-slider {
    grid-column: span 12;
}

.home.horizontal-slider .slide-zone {
    padding-bottom: 56px;
}

.horizontal-slider .slide-zone {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 16px;
    -webkit-overflow-scrolling: touch;
}

.horizontal-slider .slide-zone::-webkit-scrollbar {
    background: green;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 34px;
    height: 8px;
}

.horizontal-slider .slide-zone::-webkit-scrollbar-thumb {
    background: linear-gradient(153.06deg, #26f596 -0.31%, #0499f2 99.69%);
    border-radius: 34px;
}

.list-grid .event-card {
    grid-column: span 3;
}

.event-card {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 288px;
    height: fit-content;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 24px;
    overflow: hidden;
}

.event-card .event-card-header {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.event-card .event-card-header .event-card-cover {
    width: 100%;
    height: 142px;
    background-color: rgba(0, 0, 0, 0.1);
}

.event-card .event-card-header .event-card-cover img {
    width: 100%;
}

.event-card .event-data {
    width: 100%;
    height: fit-content;
    padding: 12px;
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.event-card-header .event-card-globals {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    position: absolute;
    width: 100%;
    height: fit-content;
    left: 0px;
    bottom: 10px;
}

.event-card-header .event-card-globals .even-fund {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;

    width: fit-content;
    height: fit-content;
    background: linear-gradient(153.06deg, #08c8f6 -0.31%, #4d5dfb 99.69%);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}

.event-card-header .event-card-globals .even-fund .fund-amount {
    font-family: var(--font-numeric);
    line-height: 1;
}

.event-card-header .event-card-globals .event-time-discount {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    gap: 10px;
    font-size: 10px;
    width: fit-content;
    height: fit-content;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(4px);
    border-radius: 12px;
}

.event-card .event-data .event-card-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 0 12px; */
    width: 100%;
}

.event-card .event-data .event-card-info .event-name {
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
}

.event-card .event-data .event-card-info .game-infos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 60%;
}

.event-card .event-data .event-card-info .game-infos .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.event-card .event-data .event-card-info .game-infos .info span.icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.event-card .event-data .event-card-info .game-infos .info span.value {
    font-family: var(--font-numeric);
    color: var(--gris-400);
}

.event-card .event-data .event-action-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* padding: 0 12px; */
    gap: 12px;
    width: 100%;
    height: 100%;
}

.event-card .event-data .event-action-section button:first-child {
    flex-grow: 1;
}

.event-card .event-data .event-action-section button {
    display: flex;
    height: 100%;
    background: rgba(255, 255, 255, 0.15);
}

.event-card .event-data .event-action-section button:hover {
    background-color: rgba(255, 255, 255, 0.3);
    outline: var(--vert-50);
}

.event-card .event-data .event-action-section button.danger {
    display: flex;
    height: 100%;
    background: rgba(var(--error-500-rgb), 0.55);
}

.event-card .event-data .event-action-section button.danger:hover {
    background-color: rgba(var(--error-500-rgb), 0.65);
    outline: var(--error-500);
}

.event-card .event-data .event-action-section button span svg {
    width: 16px;
    height: 16px;
}

.event-card .event-data .event-suscribers {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 0 12px; */
    gap: 10px;
}

.event-card .event-data .event-suscribers .suscribers {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 0px 0px 4px;
}

.event-card .event-data .event-suscribers .suscribers .user-avatar {
    overflow: hidden;
    cursor: pointer;
    width: 24px;
    height: 24px;
    overflow: hidden;
    background-color: var(--gris-800);
    border: 2px solid var(--vert-500);
    border-radius: 16px;

    margin: 0px -4px;
}

.event-card .event-data .event-suscribers .suscribers-count {
}

.section-large-cta {
    grid-column: 5 / 9;
}

.section-large-cta button {
    width: 100%;
    justify-content: space-between;
}

/* 
      Offers section
*/

.widget-group-grid {
}

.widget-group-grid {
}

.home-card {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 16px;
}

.home-card .home-card-cover {
    width: 100%;
    height: 264px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.home-card .home-card-cover img {
    width: 100%;
    height: 264px;
    border-radius: 24px 24px 0px 0px;
    object-fit: cover;
}

.home-card.caption-on-image .home-card-cover img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 264px;
    border-radius: 24px;
    object-fit: cover;
}

.home-card.caption-on-image .home-card-cover .home-card-caption {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 0px 40px;
    gap: 12px;
    z-index: 1;
}

.home-card.caption-on-image .home-card-cover .home-card-caption .caption-hint {
    font-weight: 500;
    font-size: 8px;
    line-height: 1.25;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
}

.home-card.caption-on-image .home-card-cover .home-card-caption .caption-text {
    font-weight: 800;
    font-size: 18px;
    line-height: 125%;
    text-align: center;
    text-transform: uppercase;
    width: 70%;
}

.shadow-style1 {
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 2px rgba(255, 221, 0, 0.54);
}

.shadow-style2 {
    color: #fe5858;
    text-shadow: 2px 2px 2px rgba(255, 221, 0, 0.84);
    font-size: auto;
    font-size: 220% !important;
}

.home-card .home-card-action {
}

.home-card .home-card-action .home-card-button {
}

.home-card > .home-card-caption {
    display: flex;
    gap: 12px;
    flex-direction: column;
    padding: 0 16px;
}

.home-card > .home-card-caption .caption-date,
.home-card > .home-card-caption p {
    font-size: 12px;
    line-height: 160%;
    color: rgba(255, 255, 255, 0.8);
}

/* 
   End home slider
*/

/* 
   Footer
*/

/* 
   Footer
*/

.footer {
    row-gap: 32px;
}

.footer .container {
    row-gap: 32px;
}

.footer-links {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 40px 0;
    margin-bottom: 32px;
}

.footer-links .column {
}

.footer-links .column.social {
}

.footer-links .column li {
    margin-bottom: 8px;
}

.footer-links .column li a {
    color: inherit;
}

.footer-links .column li.icons {
    display: flex;
    gap: 24px;
    margin-bottom: 16px;
}

.footer-links .column li.icons a svg {
    width: 24px;
    height: 24px;
}

.footer-links .column li.icons {
}

.footer-links .column li:first-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    padding-bottom: 16px;
}

.footer-links .column li.download {
    display: flex;
    gap: 24px;
    margin-top: 16px;
}

.footer-links .column li.download a svg {
    width: auto;
    height: 32px;
}

.footer-policy {
}

.footer-policy .copyright {
    grid-column: 1 / 8;
}

.footer-policy .fapfap-policy {
    grid-column: 1 /10;
    font-size: 10px;
}

.footer-policy .fapfap-policy h5 {
    margin-bottom: 14px;
    font-size: 12px;
}

.footer-policy .fapfap-policy p {
}

/* End Footer */

/* Authentification */

.full-page {
    height: 100vh;
}

.full-page .auth-screen,
.full-page .tour-screen {
    height: 100vh;
    position: relative;
}

.form-side {
    grid-column: span 6;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.form-container {
    height: fit-content;
    width: fit-content;
    max-width: 400px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border: 1px solid var(--gris-100);
    border-radius: 24px;
    margin-top: 48px;
}

.form-container.colored {
    background-color: var(--vert-50);
}

.form-side .form-header {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    gap: 16px;
}

.form-side .form-header .form-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    color: var(--gris-800);
}

.form-side .form-header .form-subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: var(--gris-700);
}

.form-side .form-header .form-description {
    margin: 0;
    color: var(--gris-600);
}

.form-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0px 0px;
    gap: 16px;
    width: 100%;
}

.form-content .known-person {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 8px;
    gap: 12px;
    border: 1px solid var(--gris-50);
    border-radius: 16px;
    width: 100%;
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.form-content .known-person:hover {
    border: 1px solid var(--vert-200);
    -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.form-content .known-person .user-avatar,
.form-content .known-person .user-avatar img {
    width: 56px;
    height: 56px;
}

.form-content .known-person .user-identification {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
}

.form-content .known-person .user-identification .user-pseudo {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
}

.form-content .known-person .user-identification .user-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    justify-content: center;
}

.form-content .known-person .user-identification .user-info .user-phone,
.form-content .known-person .user-identification .user-info .user-email {
    font-weight: 400;
    font-size: 12px;
    color: var(--gris-600);
}

.form-container a.button,
.form-container button {
    align-self: stretch;
}

form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    height: fit-content;
}

form fieldset {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
}

.flexcheck {
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    gap: 12px;
}

.flexcheck.check-with-image {
    gap: 0;
}

.flexcheck.check-with-image .check-item {
    width: calc(33% - 8px);
    margin-right: 8px;
}

.flexcheck.check-with-image.large .check-item {
    width: calc(50% - 8px);
    margin-right: 8px;
}

.flexcheck.check-with-image.large .check-item img {
    width: 96px;
    height: auto;
    margin-top: 12px;
}

.flexcheck.check-with-image .check-item img {
    width: 64px;
    height: auto;
    margin-top: 12px;
}

.flexcheck .check-line-title {
}

.flexcheck .top-label {
}

form fieldset.form-mention {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

form fieldset.form-mention p {
    color: var(--gris-600);
    margin: 0;
}

form fieldset .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
}

form fieldset .checkbox input[type='checkbox'] {
    width: 12px;
    height: 12px;

    background: #ffffff;
    border: 1px solid var(--gris-400);
    border-radius: 4px;
}

/* form fieldset .checkbox label {
   font-size: 10px;
} */

form fieldset.form-mention a {
    font-size: 10px;
    line-height: 1;
    text-decoration-line: underline;
    color: var(--gris-600);
}

form fieldset .form-require {
    color: red;
    line-height: 0.5;
}

form fieldset label {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    padding: 0px;
    gap: 2px;
    margin: 0;
    align-content: center;
    color: var(--gris-600);
}

form.tour-form fieldset label {
    color: var(--vert-500);
    font-size: 12px;
    width: 100%;
}

form.tour-form fieldset label .char-cont {
    flex: 3;
    text-align: right;
    color: var(--gris-400);
}

form fieldset input {
    padding: 12px;
    background: #ffffff;
    border: 1px solid var(--gris-200);
    border-radius: 16px;
    width: 100%;
    height: fit-content;
    /*
    -webkit-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);*/
}

form fieldset input:focus {
    outline: 1px solid var(--vert-500);
    border: 1px solid var(--vert-500);
    -webkit-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

form fieldset input::-ms-input-placeholder,
form fieldset input::-moz-placeholder,
form fieldset input::-webkit-placeholder,
form fieldset input::placeholder {
    color: red;
}

fieldset.constrain {
}

fieldset.constrain label .for {
    color: white;
}

fieldset.constrain label .for span {
    font-size: 90%;
}

fieldset.constrain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
}

.icon-input {
    background: var(--opaced-bkg);
    border: 1px solid var(--gris-200);
    padding: 12px;
    border-radius: 16px;
    width: 100%;
    display: flex;
    gap: 12px;
}

.icon-input input {
    padding: 0;
    background-color: transparent;
    border: none;
    width: 100%;
    height: fit-content;
    border-radius: 0;
}

.icon-input input:hover,
.icon-input input:focus {
    border: none;
    outline: none;
    color: white;
}

.auth-scene-side .auth-scene-content {
}

.auth-scene-side {
    grid-column: span 6;
    background-color: var(--vert-950);
    color: white;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.document-side {
    width: 100%;
    height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.document-side h1 {
    font-size: 14px;
    margin-bottom: 24px;
}

.document-side h3 {
    font-size: 12px;
    margin-bottom: 16px;
    margin-left: 8px;
}

.document-side p {
    font-size: 12px;
    margin-bottom: 24px;
    margin-left: 12px;
    line-height: 1.6;
}

.auth-scene-side .auth-scene-content .widget-group-column,
.auth-scene-side .scene-title {
    margin: auto;
    width: calc(100% - 96px);
}

.auth-scene-side .auth-scene-content .top-korateur {
    transform: scale(0.7);
}

.auth-scene-side .auth-scene-content .scene-title {
    font-size: 24px;
    line-height: 125%;
    /* or 30px */

    /* Livear 2 */

    background: linear-gradient(153.06deg, #93fb9d -0.31%, #09c7fb 99.69%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.auth-scene-side .scene-title h3 {
    font-size: 16px;
}

.auth-scene-side p {
    font-size: 12px;
}

.auth-scene-side::before {
    content: '';
    position: absolute;
    right: -64px;
    top: 0;
    width: 64px;
    height: 100vh;
    background-color: var(--vert-950);
}

/* 
   End Authentification pages
*/

/* 
   App pages
*/
/* ------- configuration des grille --------*/
.app-page .grid-ful,
.dashboard .grid-ful {
    height: 100vh;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 96px 1fr 1fr 1fr 1fr;
    gap: 16px 16px;
}

.left-side {
    grid-area: 1 / 1 / 6 / 4;
}

.app-container {
    grid-area: 1 / 4 / 6 / 13;
}

.app-topbar {
    grid-area: 1 / 4 / 2 / 13;
}
/* 
 .page-content {
   grid-area: 2 / 4 / 6 / 10;
}  */

main {
    grid-row-start: 2;
    grid-column-start: 4;
    grid-row-end: 6;
    grid-column-end: 13;
    overflow-y: auto;
    min-height: 80vh;

    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(9, 1fr);
    gap: 24px 24px;
    width: 100%;
    padding-right: 24px;
}

main .page-content {
    grid-column-start: 1;
    grid-column-end: 7;
}
main .right-side {
    grid-column-start: 7;
    grid-column-end: 10;
}

.list-page-content,
.widget-page-content {
    grid-area: 2 / 4 / 6 / 13;
    overflow-y: auto;
    min-height: 80vh;
}

.playing-page {
    /*height: 100vh;
    max-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 80px 1fr;
    grid-auto-columns: 1fr;*/
    gap: 16px;
    padding: 16px;
    /*grid-auto-flow: row;
    grid-template-areas:
        'table-page-top table-page-top table-page-top table-page-top table-page-top table-page-top table-page-top table-page-top table-page-top table-page-top table-page-top table-page-top'
        'table-space table-space table-space table-space table-space table-space table-space table-space table-space message-space message-space message-space';*/
}
/* ---------- end grid parameters ---------- */

.widget-page-content {
    display: flex;
    row-gap: 48px;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
}

.widget-page-content .page-section-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(9, 1fr);
    gap: 24px 24px;
    width: 100%;
}

.widget-page-content .page-section .section-title,
.widget-page-content .page-section-grid .section-title {
    width: 100%;
    grid-column: span 9;
}

.left-side,
.right-side {
    position: relative;
}

.left-side {
    height: 100%;
}

.sub-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 32px;
    height: 100%;
    background: #0c180f;
    box-shadow: 5px 4px 34px rgb(0 0 0 / 5%);
    backdrop-filter: blur(54px);
    border-radius: 24px;
}

.sub-menu .top-menu,
.top-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 100%;
}

.sub-menu .user-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 4px;
    width: 100%;
    cursor: pointer;
}

.sub-menu .top-menu .application,
.sub-menu .user-menu .user,
.creator .user,
.application {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
}

.sub-menu .top-menu .application .app-logo,
.application .app-logo {
    height: 40px;
    width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--vert-500); */
    border-radius: 12px;
}

.sub-menu .user-menu .user .user-avatar,
.creator .user .user-avatar,
.tour-share .user .user-avatar {
    overflow: hidden;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
}

.creator .user .user-avatar {
    overflow: hidden;
    cursor: pointer;
    width: 48px;
    height: 48px;
    background-color: transparent;
}

.sub-menu .top-menu .application .identity,
.sub-menu .user-menu .user .user-id,
.creator .user .user-id {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.sub-menu .user-menu .user .user-id img,
.creator .user .user-id img {
}

.sub-menu .top-menu .application .identity .name,
.sub-menu .user-menu .user .user-id .user-name,
.creator .user .user-id .user-name {
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    color: #ffffff;
}

.sub-menu .user-menu .user .user-id .user-name,
.creator .user .user-id .user-name {
    font-weight: 300;
}

.creator .user .user-id .user-name {
    font-weight: 600;
}

.sub-menu .user-menu .user .user-id .user-role,
.creator .user .user-id .user-role {
    color: var(--opaced-text);
    font-weight: 500;
    font-size: 90%;
}

.sub-menu .top-menu .application .identity .slogan,
.sub-menu .user-menu .user .user-id .sub-infos {
    font-size: 10px;
    line-height: 1;
    display: flex;
    align-items: center;
    color: var(--opaced-text);
}

.sub-menu .user-menu .user .user-id .sub-infos {
    color: white;
}

.sub-menu .user-menu .user .user-id .sub-infos span {
    margin-left: 2px;
}

.sub-menu .user-menu .user .user-id .sub-infos .user-pseudo {
}

.sub-menu .user-menu .user .user-id .sub-infos .user-sold {
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    color: var(--yelow-500);
}

.top-menu-dropdown,
.drop-down-trigger {
    height: 32px;
    width: 32px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    -webkit-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    border-radius: 12px;
}

.openned .link-identification .drop-down-trigger {
    transform: rotate(180deg);
    -webkit-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.openned .link-identification .drop-down-trigger svg path {
    fill: white;
    fill-opacity: 1;
}

.top-menu-dropdown:hover,
.drop-down-trigger:hover {
    cursor: pointer;
    background-color: var(--opaced-bkg);
    -webkit-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.menu-links {
    width: 100%;
    flex: 1;
}

.menu-links .links {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.menu-links .links .link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 100%;
    cursor: pointer;
}

.menu-links .links .link .link-identification {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 100%;
}

.menu-links .links .link .link-identification .link-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 12px;
    color: var(--opaced-text);
}

.menu-links .links .link.openned .link-identification .link-label,
.menu-links .links .link:hover .link-identification .link-label {
    color: white;
    -webkit-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.menu-links .links .link.openned .link-identification .link-label .link-icon svg path,
.menu-links .links .link:hover .link-identification .link-label .link-icon svg path {
    stroke: white;
    stroke-opacity: 1 !important;
    stroke-width: 1.8 !important;
    -webkit-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.menu-links .links .link .link-identification .link-label .link-icon svg path {
    stroke: var(--opaced-text);
    stroke-opacity: 0.4 !important;
    stroke-width: 1 !important;
    -webkit-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.menu-links .links .link .link-identification .link-label .link-icon {
}

.menu-links .links .link.openned .link-identification .link-label .link-name {
    font-size: 12px;
    font-weight: 500;
}

.menu-links .links .link .link-identification .link-label .link-name {
    font-size: 12px;
    text-overflow: ellipsis;
    /* width: 110px; */
}

.buy-credit {
    width: fit-content;
}

.buy-credit form {
}

.buy-credit form label {
    margin: 0;
}

.buy-credit form .input-line {
    width: 100%;
    display: flex;
    justify-content: stretch;
    gap: 16px;
}

.buy-credit form .input-line input {
    padding: 8px;
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.04);
    border-radius: 16px;
    color: white;
    width: 190px;
}

.buy-credit form .input-line button {
}

.menu-links .links .drop-down {
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.menu-links .links .drop-down.openned .dropdown-sublevel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    height: fit-content;
    width: 100%;
    opacity: 1;
    -webkit-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.menu-links .links .drop-down .dropdown-sublevel {
    opacity: 0;
    height: 0;
    display: none;
    /* -webkit-transition: all .1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
   -moz-transition: all .1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
   -ms-transition: all .1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
   transition: all .1s cubic-bezier(0.61, 0.25, 0.14, 0.53); */
}

/* Add the animation: property to whichever element you want to animate */
#elementToAnimate {
}
.menu-links .links .drop-down .dropdown-sublevel li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0px 8px 32px;
    gap: 16px;
    width: 100%;
    height: fit-content;
    border-radius: 12px;
    color: var(--opaced-text);
    -webkit-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.menu-links .links .drop-down .dropdown-sublevel li a {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.menu-links .links .drop-down .dropdown-sublevel li a .notif-number {
    /* padding: 4px; */
    width: 24px;
    height: 24px;
    border-radius: 16px;
    text-align: center;
    font-size: 10px;
    background-color: var(--yelow-500);
    color: var(--vert-950);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-links .links .drop-down .dropdown-sublevel li a .notif-new {
    /* padding: 4px; */
    height: 24px;
    border-radius: 16px;
    text-align: center;
    font-size: 10px;
    background-color: var(--blue-500);
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    position: relative;
}
.menu-links .links li a .notif-new {
    /* padding: 4px; */
    height: 20px;
    border-radius: 10px;
    text-align: center;
    font-size: 10px;
    background-color: var(--blue-500);
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;
    position: relative;
}
.menu-links .links .drop-down .dropdown-sublevel li.active,
.menu-links .links .drop-down .dropdown-sublevel li:hover {
    background: rgba(255, 255, 255, 0.04);
    -webkit-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    color: white;
}

.user-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    line-height: 1;
    width: 100%;
}

.user-profile .user-avatar {
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 128px;
    height: 128px;
}

.user-profile .user-avatar img {
    width: 128px;
    height: 128px;
    border-radius: 50%;
}

.user-profile .user-id {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    align-content: center;
}

.user-profile .user-id .user-name {
    font-weight: 400;
    font-size: 18px;
    opacity: 0.7;
}

.user-profile .user-id .user-pseudo {
    font-weight: 500;
    font-size: 12px;
}

.user-profile .user-id .sub-infos {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.user-profile .user-id .user-name {
}

.user-profile .user-id .user-role {
    color: var(--opaced-text);
    font-weight: 500;
    font-size: 90%;
}

.user-datas {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.user-datas .line-label {
    opacity: 0.7;
    font-size: 11px;
    display: flex;
    justify-content: space-between;
}

.user-datas .user-line {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.user-datas .line-label .change-data {
    text-decoration: underline;
    color: var(--vert-500);
    opacity: 1;
}

.user-datas .line-value {
    font-size: 12px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
}

.user-datas .line-value .data {
}

.user-datas .line-value .value {
}

.user-datas .line-value .data .verification {
    text-transform: uppercase;
    font-size: 6px;
    padding: 4px;
    border: 1px var(--vert-400) solid;
    border-radius: 4px;
    background-color: var(--vert-800);
    color: var(--vert-500);
    letter-spacing: 1px;
    margin-left: 8px;
}
.user-datas .line-value .data .verification.danger {
    border: 1px var(--error-500) solid;
    background-color: var(--error-800);
    color: var(--error-500);
}

.user-datas .line-value .money {
    color: var(--yelow-500);
}

/* 
   App Top bar
 */

.app-container {
    /* border: 1px solid yellow; */
}

.app-topbar {
}

.app-topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(40px);
    border-radius: 16px;
    height: 72px;
}

.app-sensor {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
}

.toggle-switch {
    position: relative;
    width: 56px;
    height: 32px;
}

.toggle-switch label {
    position: absolute;
    width: 100%;
    height: 32px;
    background: var(--dark);
    border-radius: 50px;
    cursor: pointer;
}

.toggle-switch input {
    position: absolute;
    display: none;
}

.toggle-switch .slider {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: 0.3s;
}

.toggle-switch .slider span.dark {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 12px;
    height: 12px;
}

.toggle-switch .slider span.light {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 12px;
    height: 12px;
}

.toggle-switch input:checked ~ .slider {
    background: var(--light);
}

.toggle-switch.default input:checked ~ .slider {
}

.toggle-switch .slider::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: inset 28px -4px 0px 0px var(--vert-500);
    /* background-color: var(--dark); */
    transition: 0.3s;
}

.toggle-switch.default .slider::before {
    box-shadow: inset 28px -4px 0px 0px var(--gris-300);
}

.toggle-switch input:checked ~ .slider::before {
    transform: translateX(24px);
    background-color: var(--dark);
    box-shadow: none;
}

.sensor {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    gap: 10px;
    width: 40px;
    height: 40px;
    border-radius: 16px;
    cursor: pointer;
    justify-content: center;
}

.white-bkg {
    background: var(--vert-50);
}

.sensor.new-tournament {
    background: var(--vert-500);
}

.sensor.connectivity {
    background: var(--vert-500);
}

.app-topbar .topbar-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
}

.app-topbar .topbar-links a.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 16px;
    width: 40px;
    height: 40px;
    border-radius: 16px;
    position: relative;
    cursor: pointer;
}

.app-topbar .topbar-links a.button .indicator {
    position: absolute;
    right: -8px;
    top: -4px;
    width: 24px;
    height: 24px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
    background-color: var(--vert-500);
    color: white;
    border-radius: 16px;
}

.indicator.indicator-sm {
    right: 6px !important;
    top: 8px !important;
    width: 8px !important;
    height: 8px !important;
    padding: 0 !important;
}
.button.notification:hover .indicator.indicator-sm {
    background-color: #fff !important;
}
/* End App Top bar */

/* 
   Page content
 */

.page-content {
    margin: 32px 0;
    display: flex;
    flex-direction: column;
    gap: 56px;
}

.page-content::-webkit-scrollbar {
    background: green;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 34px;
    height: 4px;
    position: fixed;
    display: flex;
}

.page-content::-webkit-scrollbar-thumb {
    background: linear-gradient(153.06deg, #26f596 -0.31%, #0499f2 99.69%);
    border-radius: 34px;
    height: 4px;
}

.page-intro {
    margin-top: 48px;
}

.list-page-content .page-intro {
    margin-top: 0;
}

@media (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .list-page-content .page-intro {
        margin-top: 58px;
    }
}

h2.page-title {
    /* Bon retour parmi nous Vortexart ! */
    font-weight: 400;
    line-height: 160%;
    color: #ffffff;
}

.widget-page-content .page-title {
    grid-column: span 8;
}

.widget-page-content .page-section-grid .widget {
    height: fit-content;
}

.widget-page-content .page-section .widget.no-padding {
    padding: 0;
}

.list-page-content .page-intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
}

.list-page-content .page-section {
    margin-bottom: 56px;
}

.page-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.page-title p {
    max-width: 70%;
}

.page-filter {
}

.page-filter .filter-label {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 4px;
}

.page-filter form {
}

.page-filter form .input-line {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 4px;
}

.page-filter form .input-line label {
    color: #999999;
    font-size: 12px;
}

.page-filter form .input-line input {
    padding: 12px;
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid #59c377;
    box-shadow: 0px 0px 4px #59c377;
    border-radius: 16px;
    color: white;
    width: 100%;
}

.page-content .page-intro p {
}

.page-content .quick-start {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 16px;
    border-radius: 16px;
}

.page-content .quick-start button {
    max-width: 208px;
}

.page-content .quick-start .connected-users {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    isolation: isolate;
    flex-wrap: nowrap;
    width: calc(100% - 208px - 16px);
    height: fit-content;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.page-content .quick-start .connected-users::-webkit-scrollbar {
    /* background: green;
   background: rgba(255, 255, 255, 0.15);
   border-radius: 34px;
   height: 8px; */
    display: none;
}

.page-content .quick-start .connected-users::-webkit-scrollbar-thumb {
    /* background: linear-gradient(153.06deg, #26F596 -0.31%, #0499F2 99.69%);
   border-radius: 34px;
   height: 8px; */
    display: none;
}

.page-content .quick-start .connected-users .connected-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 4px;
    min-width: 64px;
    height: fit-content;
}

.page-content .quick-start .connected-users .connected-user .user-avatar {
    overflow: hidden;
    cursor: pointer;
    background-color: var(--opaced-bkg);
    width: 32px;
    height: 32px;
    border-radius: 36px;
    overflow: hidden;
    border: 2px solid var(--opaced-bkg);
}

.page-content .quick-start .connected-users .connected-user .user-avatar img {
    width: 100%;
    height: 100%;
}

.page-content .quick-start .connected-users .connected-user .user-name {
    text-align: center;
    font-size: 10px;
}

.page-content .quick-start .connected-users {
}

.widget-page-content .page-section {
    width: 100%;
    height: fit-content;
}

/* End Page Content */

.right-side .activity {
    position: absolute;
}

.activity-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* 
   
List Page Content
*/
.page-section {
    width: 100%;
}

.list-grid {
    margin-top: 48px;
    width: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 16px 16px;
}

.list-grid .widget {
    grid-column: span 3;
}

.list-flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 24px;
}

.game-card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    position: relative;
    width: 100%;
    height: fit-content;
    border-radius: 24px;
}

.game-card .card-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 100%;
}

.game-card .card-title .card-name {
    font-weight: 400;
}

.game-card .card-title .timer {
    font-size: 10px;
    text-align: right;
    height: 100%;
    align-self: flex-start;
    display: flex;
    gap: 4px;
    align-items: center;
}

.game-card .players {
}

.game-card .players {
}

.game-card .creator-infos {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.game-card .creator-infos .user-avatar {
    overflow: hidden;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: var(--vert-50);
    border-radius: 24px;
    border: 1px solid var(--gris-100);
}
.game-card .creator-infos .user-avatar * {
    box-sizing: inherit;
}
.game-card .creator-infos .user-name {
}

.game-card .creator-infos .user-name span {
    font-size: 10px;
    color: var(--opaced-text);
}

/* Dashboard */
.page-section .widget {
    height: 100%;
}
.widget,
.user-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    position: relative;
}

.widget-actions {
}

.user-card .widget-actions {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: -24px;
    position: absolute;
    display: flex;
    width: 100%;
    bottom: -24px;
    left: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 16px;
}

.span1 {
    grid-column: span 1 !important;
}

.span2 {
    grid-column: span 2 !important;
}

.span3 {
    grid-column: span 3 !important;
}

.span4 {
    grid-column: span 4 !important;
}

.span5 {
    grid-column: span 5 !important;
}

.span6 {
    grid-column: span 6 !important;
}

.widget .widget-period {
}

.widget .widget-datas {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
}

.widget .widget-datas.row {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-content: flex-start;
    flex-direction: row;
}

.widget .widget-name {
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
}

.widget .widget-datas .widget-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.widget .widget-datas .widget-data.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    align-content: flex-start;
    width: 100%;
}

.widget .widget-datas .widget-data .title {
    font-size: 10px;
}

.widget .widget-datas .widget-data.center {
    align-items: center;
    text-align: center;
}

.widget .widget-datas.justify-space-between {
    justify-content: space-between;
    width: 100%;
    text-align: center;
}

.widget .widget-datas .widget-data .widget-icon {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.widget .widget-datas .widget-data.green .widget-icon {
    background-color: var(--vert-100);
    max-width: 48px;
    flex: 1;
}

.widget .widget-datas .widget-data.green-500 .widget-icon {
    background-color: var(--vert-500);
    max-width: 48px;
    flex: 1;
}

.widget .widget-datas .widget-data.blue-500 .widget-icon {
    background-color: var(--blue-500);
    max-width: 48px;
    flex: 1;
}

.widget .widget-datas .widget-data.yellow .widget-icon {
    background-color: var(--yellow-100);
}

.widget .widget-datas .widget-data.yellow-500 .widget-icon {
    background-color: var(--yelow-500);
}

.widget .widget-datas .widget-data .widget-value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 3;
}

.widget .widget-datas .widget-data .widget-value .title {
    font-size: 12px;
    width: 72px;
    display: block;
    width: 100%;
}

.widget.historic .widget-datas .widget-data .widget-value .title {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.widget.historic .widget-datas .widget-data .widget-value .text {
    opacity: 0.8;
}

.widget .widget-datas .widget-data .widget-value .value.numeric {
    font-family: var(--font-numeric);
}

.widget .widget-datas .widget-data .value {
    font-size: 14px;
    font-weight: 600;
}

.widget.historic .widget-datas .widget-data .value {
    font-size: 12px;
    font-weight: 600;
}

.widget .widget-datas .widget-data .data-image img {
    width: fit-content;
    height: 96px;
}

.historic {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    gap: 24px;
}

.historic-line {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.orange-money {
    background-color: var(--orange-money);
    color: var(--gris-500);
    padding-bottom: 40px;
}

/* End Dashboard */
/* floating button for new game proccess and modal */

.new-game-trigger {
    width: 72px;
    height: 72px;
    position: fixed;
    z-index: 9999;
    right: 32px;
    bottom: 48px;
    background-color: var(--vert-500);
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    cursor: pointer;
}

.new-game-trigger:hover {
    -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transform: scale(1.1);
}

.overlay {
    opacity: 0;
    width: 100wh;
    height: 100vh;
    position: absolute;
    left: -100vh;
    top: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.6s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.6s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.6s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.overlay .overlay-cache {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.overlay.opened {
    opacity: 1;
    position: fixed;
    z-index: 10000;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    -webkit-transition: all 0.6s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.6s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.6s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.6s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.overlay .modal {
    display: none;
}

.overlay .modal.active-modal {
    position: relative;
    height: fit-content;
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 32px;
    background-color: var(--vert-950);
    border-radius: 24px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -webkit-transition-delay: 250ms;
    -moz-transition-delay: 250ms;
    -ms-transition-delay: 250ms;
    transition-delay: 250ms;
}
.overlay .modal.sm {
    width: 480px;
}
.overlay .modal.md {
    width: 680px;
}
.overlay .modal.tour.active-modal {
    height: 100vh;
    width: 100vw;
    background-color: var(--vert-950);
    padding: 0;
    border-radius: 0;
}

.modal .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 100%;
}

.modal .modal-header .modal-title {
    color: #fff;
}

.modal-close {
    width: 40px;
    height: 40px;
    background: rgba(242, 72, 34, 0.32);
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-return {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}

.modal .form-container {
    margin: 0;
    border: none;
    padding: 0;
    width: 100%;
}

.modal .form-container {
    max-width: 100%;
}

/* end floating button for new game proccess and modal */

/* Tournement flow screens  */

.tour-form-side {
    grid-column: span 4;
    height: calc(100% - 64px);
    padding: 16px;
    position: relative;
    border-radius: 16px;
}

.tour-forms {
    width: 100%;
    display: block;
    /*height: 100%;*/
}

.tour-forms .tour-form-item {
    display: none;
}

.tour-forms .tour-form-item.active-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    position: relative;
    height: fit-content;
}

.tour-form-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 100%;
}

.breadcrumb {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.breadcrumb .feed {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    color: var(--gris-400);
}

.creator {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
}

.tour-plan-side {
    grid-column: span 8;
    height: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    position: relative;
}

.plan-header {
    display: flex;
    gap: 12px;
    flex-direction: column;
}

.plan-header .tour-date {
    width: 80px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px 12px 12px 12px;
    border-radius: 16px;
    position: relative;
}

.plan-header .tour-date::before {
    border-top: 8px solid var(--vert-500);
    border-radius: 16px 16px 0px 0px;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    height: 12px;
    width: 100%;
    background: var(--vert-500);
}

.plan-header .tour-day {
    font-size: 24px;
    font-weight: 600;
}

.plan-header .tour-month {
    font-size: 10px;
}

.plan-header .long-date {
    color: var(--vert-500);
}

.tour-game-id {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0px;
    gap: 12px;
    width: 100%;
}

.tour-game-id .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
}

.tour-game-id .description .tour-name {
    color: var(--gris-400);
}

.tour-game-id .description .play-type {
    color: var(--gris-600);
}

.tour-share {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 16px;
    width: 100%;
    border-radius: 16px;
}

.tour-share .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
}

.tour-share .user .user-avatar {
    overflow: hidden;
    cursor: pointer;
    width: 48px;
    height: 48px;
}

.tour-share .user .user-avatar img {
}

.tour-share .user .user-name {
    font-weight: 500;
}

.tour-share .user .user-name span {
    font-weight: 300;
    color: var(--gris-400);
}

.tour-share .share-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.tour-plan-action {
    width: 100%;
    /*position: absolute;*/
    bottom: 0;
    left: 0;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-top: 1px solid var(--opaced-bkg);
    background-color: var(--vert-950);
    border-radius: 0 0 16px 16px;
}

.tour-plan-action .tour-progression {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.tour-plan-action .tour-progression .tour-progresion-item {
    flex: 1;
    height: 8px;
    background-color: var(--opaced-bkg);
    border-radius: 12px;
}

.tour-plan-action .tour-progression .tour-progresion-item.passed {
    background-color: var(--vert-500);
}

.tab {
    height: fit-content;
    width: 100%;
}

.tab .tab-header {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    border-bottom: 1px solid var(--opaced-bkg);
}

.tab .tab-header .tab-thumb {
    font-size: 12px;
    width: fit-content;
    padding: 8px 8px 16px 8px;
}

.tab .tab-header .tab-thumb.active {
    color: var(--vert-500);
    font-weight: 500;
    position: relative;
    cursor: pointer;
}

.tab .tab-header .tab-thumb.active::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    height: 4px;
    background-color: var(--vert-500);
    width: 100%;
}

.tab .tab-content {
    width: 100%;
    height: fit-content;
}

.tab .tab-content .list-grid .widget {
    grid-column: span 4;
}

/* --------- Responsive Ajustement ---------*/
@media /*(min-width: 320px) and */(max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .top-bar {
        padding: 16px 0;
    }

    .logo {
        width: 32px;
        height: 32px;
    }

    .web-hide {
        display: flex !important;
    }

    .mobile-hide {
        display: none !important;
    }

    /*  ajustement de la grille  */
    .left-side {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 1;
        grid-column-end: 5;
        height: 100vh;
        backdrop-filter: blur(24px);
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        width: 100vw;
        transform: translateX(-100vw);
        z-index: 10001;
        -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    }

    .left-side.open {
        transform: translateX(0);
        -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    }

    /* Left Menu Ajustments */

    .sub-menu {
        width: calc(100% - 80px);
        border-top-left-radius: 0;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 0;
    }

    .close-sub-menu {
        position: fixed;
        top: 28px;
        right: 16px;
        background-color: var(--opaced-bkg);
        cursor: pointer;
    }

    /* -------------- App topBar -------------- */

    .app-topbar {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 1;
        grid-column-end: 5;
        border-radius: 0;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
        width: 100vw;
    }
    .user-sold {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .app-topbar *.opaced {
        background-color: transparent !important;
    }

    .list-page-content,
    .widget-page-content {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 1;
        grid-column-end: 5;
        overflow-y: auto;
        min-height: 80vh;
        padding: 30px 16px 16px 16px;
        width: 100%;
    }

    .list-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    /* -------------- Main -------------- */
    main {
        grid-template-columns: repeat(4, 1fr);
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 1;
        grid-column-end: 5;
        padding: 0;
        overflow-x: hidden;
    }

    main .page-content {
        grid-column-start: 1;
        grid-column-end: 5;
    }

    .page-intro {
        margin-top: 96px;
        padding: 16px;
    }

    .page-content {
        gap: 40px;
    }

    .page-content .quick-start {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        gap: 16px;
        border-radius: 0;
    }

    h2.page-title {
        font-weight: 400;
        line-height: 160%;
        color: #ffffff;
        font-size: 24px;
        margin-bottom: 24px;
    }

    .page-intro .user-stats {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .page-intro .user-stats .user-sold {
        font-weight: 400;
        font-size: 14px;
        color: white;
        opacity: 0.6;
    }
    .page-intro .user-stats .user-sold .value {
        opacity: 1;
        color: var(--yelow-500);
    }

    .widget-group {
        position: relative;
        padding: 16px;
        gap: 24px;
    }

    .list-grid .widget {
        grid-column: span 4;
    }

    .info-card-with-background {
        padding: 16px;
        display: flex;
        border-radius: 32px;
    }

    /* From dashboard */

    .widget-page-content .page-section-grid {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px 24px;
        width: 100%;
    }

    .widget-page-content .page-section .section-title,
    .widget-page-content .page-section-grid .section-title {
        width: 100%;
        grid-column: span 4;
    }
    .mob-span4 {
        grid-column: span 4 !important;
    }

    .widget-page-content .page-section-grid .widget.mob-scroll-x {
        padding-right: 0;
        padding-left: 0;
    }

    .widget-page-content .page-section-grid .widget.mob-scroll-x .widget-datas::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
    .widget-page-content .page-section-grid .widget.mob-scroll-x .widget-datas {
        height: fit-content;
        overflow: auto;
        padding-left: 24px;
    }

    .widget.mob-scroll-x .widget-name {
        padding-left: 24px;
    }

    .tour-form-side {
        border-radius: 0;
        height: calc(100vh + 96px);
        margin-bottom: 96px;
        margin-top: 72px;
    }

    .tour-plan-action {
        width: 100%;
        position: fixed;
    }

    .tour-form-header {
        position: fixed;
        padding: 16px;
        top: 0;
        left: 0;
        background-color: var(--vert-950);
    }

    /* Modal */
    .overlay .modal.active-modal {
        width: calc(100% - 48px);
    }

    .new-game-trigger {
        width: 48px;
        height: 48px;
        position: fixed;
        z-index: 9999;
        right: 16px;
        bottom: 24px;
        background-color: var(--vert-500);
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        align-items: center;
        border-radius: 36px;
        cursor: pointer;
    }

    .new-game-trigger .icon,
    .new-game-trigger .icon svg {
        width: 24px;
        height: 24px;
    }
    /* Menu section */

    .menu-trigger {
    }

    .menu-trigger .menu-css-icon {
        width: 24px;
        height: 2px;
        background-color: white;
        position: relative;
        -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    }

    .menu-trigger .menu-css-icon::after {
        content: '';
        width: 24px;
        height: 2px;
        background-color: white;
        position: absolute;
        top: -6px;
        -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    }

    .menu-trigger .menu-css-icon::before {
        content: '';
        width: 24px;
        height: 2px;
        background-color: white;
        position: absolute;
        top: 6px;
        -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    }

    .menu-trigger .menu-css-icon:hover::before {
        top: 8px;
        -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    }

    .menu-trigger .menu-css-icon:hover::after {
        top: -8px;
        -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    }

    .menu-trigger.opened .menu-css-icon {
        background-color: transparent;
    }

    .menu-trigger.opened .menu-css-icon::before {
        transform: rotate(45deg);
        top: 0;
        left: 0;
    }

    .menu-trigger.opened .menu-css-icon::after {
        transform: rotate(-45deg);
        top: 0;
        left: 0;
    }

    .menu-link {
        position: fixed;
        width: 80%;
        height: 100%;
        right: -100vw;
        top: 0;
        z-index: -1;
        padding: 56px 24px 24px 24px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(24px);
        -webkit-transition: all 0.41s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        transition: all 0.41s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    }

    .menu-link.opened {
        right: 0;
        top: 0;
        z-index: 999;
    }

    .menu-link .menu-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        gap: 64px;
    }

    .menu-link .menu-content .nav-link,
    .menu-link .menu-content .app-link {
        display: flex;
        gap: 8px;
        flex-direction: column;
        text-align: center;
    }

    .menu-link .menu-content .nav-link li {
        padding: 16px 8px;
    }

    .menu-link .menu-content .nav-link a {
        font-size: 12px;
    }

    .menu-link .menu-content .app-link {
    }

    .hero-intro {
        height: 100vh;
        min-height: 700px;
        padding-top: 128px;
    }

    .intro-content {
        grid-column: span 4;
    }

    .intro-content .intro-title {
        font-size: 24px;
        color: var(--vert-500);
        font-weight: 600;
    }

    .intro-content .intro-text {
        font-size: 12px;
    }

    .intro-cta {
        align-self: center;
        margin-top: 16px;
    }

    .intro-scene .scene-table .empty-table {
    }

    .intro-scene {
        height: 90%;
    }

    .empty-table {
        width: 300px;
        height: 168px;
    }

    .cube-element {
        width: 40px;
        height: 40px;
        left: calc(80% - 80px / 2);
        top: calc(60% - 80px / 2);
    }

    .card-element {
        width: 40px;
        height: 40px;
        left: calc(40% - 40px / 2);
        top: calc(60% - 40px / 2);
    }

    .pieces-element {
        width: 40px;
        height: 40px;
        left: calc(26% - 40px / 2);
        top: calc(79% - 40px / 2);
    }

    .table-playboy-bottom-right {
        bottom: -20px;
        right: -20px;
        width: 60px;
        height: 60px;
    }

    .table-playboy-bottom-left {
        bottom: -20px;
        left: -20px;
        width: 60px;
        height: 60px;
    }

    .table-playboy-human-bottom-center {
        bottom: -20px;
        left: calc(50% - 30px);
        width: 60px;
        height: 60px;
    }

    .table-playgirl-top-right {
        top: -20px;
        right: -20px;
        width: 50px;
        height: 50px;
    }

    .table-playboy-top-left {
        top: -20px;
        left: -20px;
        width: 60px;
        height: 60px;
    }

    .table-scene-biscuit {
        top: calc(30% - 10px);
        left: 60px;
        width: 20px;
        height: 20px;
    }

    .table-scene-heart {
        top: calc(50% - 17px);
        right: 20px;
        width: 35px;
        height: 35px;
    }

    .message-type-element {
        top: calc(50% - 50px);
        left: calc(50% - 50px);
        width: 100px;
        height: 100px;
    }

    .download-link {
        grid-column: span 4;
    }

    .top-korateur {
        grid-column: span 4;
    }

    .top-section {
        grid-column: span 4;
    }

    .section-header {
        grid-column: span 4;
    }
    .mobile-padding-security {
        padding-left: 16px;
        padding-right: 16px;
    }
    .group-container {
        display: flex;
        flex-direction: column;
    }

    .group-content:last-child {
        padding-bottom: 0px;
    }

    .podium {
        flex: none;
        width: 100%;
    }

    .home.classement {
        flex: none;
        width: 100%;
        margin-bottom: calc(24px + 42px);
    }

    .app.widget-group {
        position: relative;
        border-radius: 0;
    }

    .classement-btn {
        position: absolute;
        bottom: 24px;
        left: 24px;
    }

    .card-illustration.trophee-podium {
        width: 90px;
        height: 74px;
        top: -35px;
        right: -20px;
    }

    .group-sub-title h4 {
        margin: 0;
        font-size: 14px;
    }

    .group-header .group-title {
        font-size: 16px;
    }

    .date-navigator .date {
        text-align: center;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .section-header h2 {
        font-size: 24px;
    }

    .info-panel,
    .horizontal-slider,
    .section-large-cta {
        grid-column: span 4;
    }

    .players-on-line {
        transform: scale(0.5);
        right: 16px;
        bottom: 0;
    }

    .floating-card {
        display: none;
    }

    @keyframes scale-down-right {
        0% {
            transform: scale(0.6);
            transform-origin: right center;
            -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
            -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
            -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
            transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        }

        100% {
            transform: scale(0.5);
            transform-origin: right center;
            -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
            -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
            -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
            transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        }
    }

    @keyframes scale-down-up {
        0% {
            transform: scale(0.5);
            transform-origin: right center;
            -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
            -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
            -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
            transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        }

        100% {
            transform: scale(0.6);
            transform-origin: right center;
            -webkit-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
            -moz-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
            -ms-transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
            transition: all 0.3s cubic-bezier(0.61, 0.25, 0.14, 0.53);
        }
    }

    .form-side {
        grid-column: span 4;
    }

    .auth-scene-side {
        display: none;
    }

    .form-container {
        width: 100%;
        min-width: fit-content;
    }

    .form-content .known-person .user-avatar,
    .form-content .known-person .user-avatar img {
        width: 32px;
        height: 32px;
    }

    .form-content .known-person .user-identification .user-info .user-phone,
    .form-content .known-person .user-identification .user-info .user-email {
        font-weight: 400;
        font-size: 10px;
        color: var(--gris-600);
    }

    .form-container {
        margin-top: 96px;
        margin-bottom: 48px;
    }
}

@media (min-width: 568px) {
    /* tablet portrait */

    .mobile-hide {
        display: flex !important;
    }

    .web-hide {
        display: none !important;
    }

    .hero-intro {
        height: 64vh;
        min-height: 760px;
        padding-top: 128px;
    }

    .top-korateur {
        grid-column: span 12;
    }

    .top-section {
        grid-column: span 12;
    }

    .form-side {
        grid-column: 4 / 10;
    }

    .auth-scene-side {
        display: none;
    }
}

@media (min-width: 912px) {
    .web-hide {
        display: none !important;
    }

    .top-korateur {
        grid-column: span 8;
    }

    .top-section {
        grid-column: span 4;
    }

    .podium {
        flex: 2;
    }

    .group-list-item .user-info .user-avatar {
        overflow: hidden;
        cursor: pointer;
        width: 32px;
        height: 32px;
    }

    .group-list-item .user-info .user-avatar.online {
        border: 2px solid var(--vert-500);
    }

    .group-list-item .user-info .user-name {
        font-size: 14px;
        line-height: 1;
    }

    .group-list-item .statistics .bonus {
        font-size: 14px;
    }

    .widget-header .widget-label {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    /* .widget {
      padding: 16px;
      border-radius: 16px;
   } */

    .info-card .card-name {
        font-size: 14px;
    }

    .form-side {
        grid-column: span 6;
    }

    .auth-scene-side {
        display: flex;
        grid-column: span 6;
    }
}

/* Playing page  */

.table-page-top {
    grid-area: table-page-top;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.table-page-top .top-menu {
    width: fit-content;
}

.table-page-top .top-menu .application .app-logo {
    height: 40px;
    width: 40px;
}

.table-page-top .game-config {
    display: flex;
    gap: 16px;
    align-items: center;
}

.message-space {
    grid-area: message-space;
    border: 1px solid yellow;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 24px;
    border: solid rgba(255, 255, 255, 0.04) 1px;
    height: calc(100vh - 56px - 80px);
    max-height: calc(100vh - 64px - 80px);
}

.ms-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 16px;

    width: 100%;
    height: fit-content;

    background: rgba(255, 255, 255, 0.04);
    border-radius: 24px 24px 0px 0px;
}

.ms-conversation {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 8px 8px 8px;
    gap: 24px;

    width: 100%;
    height: calc(100% - 112px);
    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
    position: relative;
    overflow: auto;
}

.ms-conversation .ms-indicator {
    background: rgba(255, 255, 255, 0.04);
    border-radius: 16px;
    padding: 4px 16px;
    font-size: 10px;
    position: absolute;
    top: 8px;
}

.ms-conversation .ms {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-end;
    gap: 8px;
}

.ms-conversation .ms.me {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.ms-conversation .ms .messages {
    width: fit-content;
    max-width: 208px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.ms-conversation .ms.me .messages {
    width: fit-content;
    max-width: 208px;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    gap: 4px;
}

.ms-conversation .ms .messages .ms-content {
    width: fit-content;
    font-size: 12px;
    padding: 8px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.04);
}

.ms-conversation .ms .messages .ms-content.writting {
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 16px;
}

.ms-conversation .ms.me .ms-author {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.ms-conversation .ms .ms-author .me {
    font-size: 8px;
    font-weight: 500;
    color: var(--vert-500);
}

.ms-conversation .ms .ms-author .time {
    font-size: 8px;
    opacity: 0.7;
}

.ms-conversation .ms .ms-author .user-avatar {
    overflow: hidden;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.04);
    overflow: hidden;
}

.ms-conversation .ms .ms-author .user-avatar img {
    width: 100%;
    background-size: cover;
}

.ms-conversation .conversations {
    width: 100%;
    height: fit-content;
}

.ms-box {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 16px;
    gap: 12px;

    width: 100%;
    height: fit-content;
    flex: none;
    order: 2;
    flex-grow: 0;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 0px 0px 24px 24px;
}

.ms-box textarea {
    background-color: transparent;
    border: none;
    height: 16px;
    width: 100%;
    outline: none;
    align-self: stretch;
    color: #fff;
}

.ms-box textarea {
    background: none;
    border: none;
    outline: none !important;
    resize: none;
    color: rgba(255, 255, 255, 0.7);
    font-size: 11px;
    height: 24px;
    margin: 0;
    padding-right: 20px;
    width: 265px;
    overflow: visible;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

.ms-box textarea::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

textarea:focus:-webkit-placeholder {
    color: transparent;
}

.table-space {
    grid-area: table-space;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 24px;
    position: relative;
}

.game-option {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.table-space .table-top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: space-between;
}

.option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    width: fit-content;
    height: fit-content;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 12px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
}

.table-space .timer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}
.table-space .timer .timming {
    font-size: 24px;
    font-weight: 400;
}

.table-space .timer .current-player {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
}

.table-space .timer .current-player .user-avatar {
    overflow: hidden;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    overflow: hidden;
}

.table-space .timer .current-player .user-avatar img {
    width: 24px;
    height: 24px;
}

.player-side {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.player-side .active-player {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 16px;
    align-items: center;
}
.player-side .active-player .user-avatar {
    width: 72px;
    height: 72px;
    border-radius: 72px;
    flex: 2;
}
.player-side .active-player .user-avatar img {
}
.player-side .active-player .user-datas {
}
.player-side .active-player .user-datas .option {
    background-color: transparent !important;
    padding: 0;
}

.player-side .active-player .user-datas .option .lost-value {
    padding: 4px;
    border-radius: 12px;
}

.player-side .active-player .user-datas {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 2;
}

.user-prizes {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
}

.player-top .user-prizes {
    gap: 8px;
    align-items: center;
}

.user-prizes .prize {
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;
}

.user-prizes .user-avatar {
    overflow: hidden;
    height: 56px;
    width: 56px;
    border-radius: 56px;
    overflow: hidden;
    border: 4px solid rgba(255, 255, 255, 0.2);
}

.player-side .player-actions {
    display: flex;
    flex-flow: row wrap;
    gap: 12px;
    max-width: 196px;
}

.player-side .cards {
    display: flex;
    flex-direction: row;
    padding-left: 24px;
}

.player-side .cards .icon-card {
    width: 72px;
    height: fit-content;
    margin-left: -24px;
    cursor: pointer;
}

/*.player-side .cards .icon-card:hover {
    margin-top: -16px;
    padding-bottom: 16px;
}*/

.player-side .cards .icon-card svg {
    width: 72px;
    height: fit-content;
}
.game-side {
    padding: 40px 24px 24px 24px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    justify-self: stretch;
    height: 100%;
}

.game-side .game-zone {
    width: 100%;
    height: 448px;
    background-image: url(../../assets/images/game\ images/table-bkg-default.png);
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: contain;
    position: relative;
}

.player {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
}

.player.player-top {
    width: fit-content;
    height: fit-content;
    width: 150px;
    position: absolute;
    left: calc(50% - 75px);
    top: -16px;
}
.player.player-top .player-id {
    padding-bottom: 4px;
}
.player.player-top .player-id .player-pseudo {
    padding: 4px 8px;
    background-color: var(--vert-opaced);
    border-radius: 16px;
    font-size: 10px;
}

.player.player-top .player-card-count {
    display: block;
    width: 50px;
    height: 30.5px;
    padding-left: 10px;
    margin-top: -4px;
}

.player.player-top .player-card-count .card {
    width: 24px;
    height: 30.5px;
    background-image: url(../../assets/images/game\ images/Card-Recto.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    float: left;
    transform: rotate(180deg);
    position: absolute;
}

.player.player-top .player-card-count .card:nth-child(1) {
    transform: rotate(-160deg);
    margin-top: -4px;
    margin-left: -24px;
}

.player.player-top .player-card-count .card:nth-child(2) {
    transform: rotate(-170deg);
    margin-top: 0px;
    margin-left: -12px;
}

.player.player-top .player-card-count .card:nth-child(3) {
    transform: rotate(-180deg);
    margin-top: 2px;
}

.player.player-top .player-card-count .card:nth-child(4) {
    transform: rotate(170deg);
    margin-top: 0px;
    margin-left: 12px;
}

.player.player-top .player-card-count .card:nth-child(5) {
    transform: rotate(160deg);
    margin-top: -4px;
    margin-left: 24px;
}

.player.player-top .player-hand {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;
    justify-content: center;
    position: absolute;
    left: 0;
    gap: 4px;
    top: 112px;
}

.player.player-top .player-hand .player-tour {
    box-sizing: border-box;

    width: 24px;
    height: 24px;

    background: rgba(0, 0, 0, 0.32);
    border: 2.14081px dashed rgba(255, 255, 255, 0.32);
    border-radius: 34.253px;
}

.player.player-top .player-hand .played-cards {
    position: absolute;
    display: flex;
    top: 32px;
    flex-direction: row-reverse;
    /* right: 0; */
    margin-left: 8px;
}

.player.player-top .player-hand .played-cards .card {
    width: 40px;
    height: 53.3333333333px;
    background: #1c4225;
    border: 1px dashed rgba(1, 20, 7, 0.32);
    border-radius: 10px;
    margin-left: -8px;
}

.player.player-top .player-hand .played-cards .card:nth-child(1) {
}

.player.player-top .player-hand .played-cards .card:nth-child(2) {
}

.player.player-top .player-hand .played-cards .card:nth-child(3) {
}

.player.player-top .player-hand .played-cards .card:nth-child(4) {
}

.player.player-top .player-hand .played-cards .card:nth-child(5) {
}

/* Player  left*/
.player.player-left {
    height: fit-content;
    width: 150px;
    position: absolute;
    left: -32px;
    top: calc(50% - 60px);
}
.player.player-left .player-id {
    padding-bottom: 12px;
}
.player.player-left .player-id .player-pseudo {
    padding: 4px 8px;
    background-color: var(--vert-opaced);
    border-radius: 16px;
    font-size: 10px;
}

.player.player-left .user-prizes {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    position: relative;
}

.player.player-left .player-card-count {
    display: block;
    width: 50px;
    height: 30.5px;
    padding-left: 10px;
    margin-top: -8px;
    position: absolute;
    top: 20px;
    left: 42px;
}

.player.player-left .player-card-count .card {
    width: 24px;
    height: 30.5px;
    background-image: url(../../assets/images/game\ images/Card-Recto.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    float: left;
    transform: rotate(90deg);
    position: absolute;
}

.player.player-left .player-card-count .card:nth-child(1) {
    transform: rotate(60deg);
    margin-left: -4px;
    margin-top: -24px;
}

.player.player-left .player-card-count .card:nth-child(2) {
    transform: rotate(70deg);
    margin-left: 0px;
    margin-top: -12px;
}

.player.player-left .player-card-count .card:nth-child(3) {
    transform: rotate(90deg);
    margin-left: 2px;
}

.player.player-left .player-card-count .card:nth-child(4) {
    transform: rotate(110deg);
    margin-left: 0px;
    margin-top: 12px;
}

.player.player-left .player-card-count .card:nth-child(5) {
    transform: rotate(120deg);
    margin-left: -4px;
    margin-top: 24px;
}

.player.player-left .player-hand {
    display: flex;
    /* padding-top: 8px; */
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;
    justify-content: flex-start;
    position: absolute;
    right: -92px;
    top: 12px;
    gap: 4px;
}

.player.player-left .player-hand .player-tour {
    box-sizing: border-box;

    width: 24px;
    height: 24px;

    background: rgba(0, 0, 0, 0.32);
    border: 2.14081px dashed rgba(255, 255, 255, 0.32);
    border-radius: 34.253px;
}

.player.player-left .player-hand .played-cards {
    position: absolute;
    display: flex;
    top: 32px;
}

.player.player-left .player-hand .played-cards .card {
    width: 40px;
    height: 53.3333333333px;
    background: #1c4225;
    border: 1px dashed rgba(1, 20, 7, 0.32);
    border-radius: 10px;
    margin-left: -8px;
}

.player.player-left .player-hand .played-cards .card:nth-child(1) {
}

.player.player-left .player-hand .played-cards .card:nth-child(2) {
    /*margin-top: 2px;*/
}

.player.player-left .player-hand .played-cards .card:nth-child(3) {
    /*margin-top: 4px;*/
}

.player.player-left .player-hand .played-cards .card:nth-child(4) {
    /*margin-top: 6px;*/
}

.player.player-left .player-hand .played-cards .card:nth-child(5) {
    /*margin-top: 8px;*/
}

/* Player right */

.player.player-right {
    height: fit-content;
    width: 150px;
    position: absolute;
    right: -32px;
    top: calc(50% - 60px);
}
.player.player-right .player-id {
    padding-bottom: 12px;
}
.player.player-right .player-id .player-pseudo {
    padding: 4px 8px;
    background-color: var(--vert-opaced);
    border-radius: 16px;
    font-size: 10px;
}

.player.player-right .user-prizes {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    position: relative;
}

.player.player-right .player-card-count {
    display: block;
    width: 50px;
    height: 30.5px;
    padding-left: 10px;
    margin-top: -8px;
    position: absolute;
    top: 20px;
    left: -32px;
}

.player.player-right .player-card-count .card {
    width: 24px;
    height: 30.5px;
    background-image: url(../../assets/images/game\ images/Card-Recto.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    float: left;
    transform: rotate(-90deg);
    position: absolute;
}

.player.player-right .player-card-count .card:nth-child(1) {
    transform: rotate(-50deg);
    margin-left: 6px;
    margin-top: -24px;
}

.player.player-right .player-card-count .card:nth-child(2) {
    transform: rotate(-70deg);
    margin-left: 0px;
    margin-top: -12px;
}

.player.player-right .player-card-count .card:nth-child(3) {
    transform: rotate(-90deg);
    margin-left: -2px;
}

.player.player-right .player-card-count .card:nth-child(4) {
    transform: rotate(-110deg);
    margin-left: 0px;
    margin-top: 12px;
}

.player.player-right .player-card-count .card:nth-child(5) {
    transform: rotate(-130deg);
    margin-left: 6px;
    margin-top: 24px;
}

.player.player-right .player-hand {
    display: flex;
    /* padding-top: 8px; */
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;
    justify-content: flex-start;
    position: absolute;
    right: 82px;
    top: 12px;
    gap: 4px;
}

.player.player-right .player-hand .player-tour {
    box-sizing: border-box;

    width: 24px;
    height: 24px;

    background: rgba(0, 0, 0, 0.32);
    border: 2.14081px dashed rgba(255, 255, 255, 0.32);
    border-radius: 34.253px;
}

.player.player-right .player-hand .played-cards {
    position: absolute;
    display: flex;
    top: 32px;
    flex-direction: row-reverse;
    right: 0;
}

.player.player-right .player-hand .played-cards .card {
    width: 40px;
    height: 53.3333333333px;
    background: #1c4225;
    border: 1px dashed rgba(1, 20, 7, 0.32);
    border-radius: 10px;
    margin-left: -8px;
}

.player.player-right .player-hand .played-cards .card:nth-child(1) {
}

.player.player-right .player-hand .played-cards .card:nth-child(2) {
    /*margin-top: 2px;*/
}

.player.player-right .player-hand .played-cards .card:nth-child(3) {
    /*margin-top: 4px;*/
}

.player.player-right .player-hand .played-cards .card:nth-child(4) {
    /*margin-top: 6px;*/
}

.player.player-right .player-hand .played-cards .card:nth-child(5) {
    /*margin-top: 8px;*/
}

.player.player-bottom .player-hand {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    gap: 4px;
}

.player.player-bottom .player-hand .player-tour {
    box-sizing: border-box;

    width: 24px;
    height: 24px;

    background: rgba(0, 0, 0, 0.32);
    border: 2.14081px dashed rgba(255, 255, 255, 0.32);
    border-radius: 34.253px;
}

.player.player-bottom {
    height: 84px;
    width: 150px;
    position: absolute;
    left: calc(50% - 75px);
    bottom: 32px;
    display: flex;
}

.player.player-bottom .player-hand .played-cards {
    position: absolute;
    display: flex;
    top: 32px;
    flex-direction: row-reverse;
    margin-left: 8px;
}

.player.player-bottom .player-hand .played-cards .card {
    width: 40px;
    height: 53.3333333333px;
    background: #1c4225;
    border: 1px dashed rgba(1, 20, 7, 0.32);
    border-radius: 10px;
    margin-left: -8px;
}

.player.player-bottom .player-hand .played-cards .card:nth-child(1) {
}

.player.player-bottom .player-hand .played-cards .card:nth-child(2) {
}

.player.player-bottom .player-hand .played-cards .card:nth-child(3) {
}

.player.player-bottom .player-hand .played-cards .card:nth-child(4) {
}

.player.player-bottom .player-hand .played-cards .card:nth-child(5) {
}

a.button:hover {
    cursor: pointer;
}
.spinner-container {
    text-align: center;
    padding: 30px 0;
}
.spinner-container svg {
    height: 100px;
}
.spinner-container.sm svg {
    height: 30px;
}
a.btn-link:hover {
    text-decoration: underline !important;
    cursor: pointer !important;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.table {
    width: 100%;
    margin: 10px 0;
}
.table thead tr,
.table tbody tr {
    width: 100%;
    border-bottom: 1px solid #fff;
}

.table thead th,
.table tbody td {
    padding: 10px 5px;
}
.swal2-container.swal2-center > .swal2-popup {
    background-color: var(--vert-950);
}

.swal2-title {
    color: #fff;
    font-size: 14px;
}
.swal2-container.swal2-backdrop-show {
    z-index: 10001;
}
.swal2-html-container {
    font-size: 14px;
}
.swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 16px;
    background: var(--vert-500);
    color: #fff;
    font-size: 14px;
    height: 42px;
    max-height: 42px;
    min-height: 42px;
}
.swal2-styled.swal2-cancel {
    border: 0;
    border-radius: 16px;
    color: #fff;
    font-size: 14px;
    height: 42px;
    max-height: 42px;
    min-height: 42px;
}
.swal2-close {
    width: 40px;
    height: 40px;
    background: rgba(242, 72, 34, 0.32);
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-right: 5px;
}
.pl-3px {
    padding-left: 3px;
}

.notif-badge {
    /* padding: 4px; */
    border-radius: 10px;
    text-align: center;
    font-size: 10px;
    background-color: var(--yelow-500);
    color: var(--vert-950);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}
.connected-user-count {
    border-radius: 13px;
    background-color: var(--vert-950);
    border: 1px solid #fafafa !important;
    border-radius: 13px;
}
.input-field .icon-input {
    transition: border 0.3s ease;
    border: 1px solid var(--gris-200);
}
.input-field .icon-input textarea {
    background: transparent;
    border: none !important;
    color: white;
}
.input-field .icon-input textarea:focus-visible {
    outline: none; /* Removes the default outline */
    border: none; /* Removes the border */
}
.input-field .icon-input:focus-within {
    box-shadow: 0px 0px 4px #59c377;
    border: 1px solid #59c377;
}

.checkbox-rect input[type='checkbox']:checked + label::after {
    content: '';
    font-size: 1.5em;
    color: var(--vert-500);
    position: absolute;
    left: 5px;
    top: 2px;
    display: inline-block;
    transform: rotate(45deg);
    height: var(--height);
    width: var(--width);
    border-bottom: var(--borderWidth) solid var(--vert-500);
    border-right: var(--borderWidth) solid var(--vert-500);
}
.max-w-auto {
    max-width: auto !important;
}
.button.button-sm {
    height: 32px !important;
    max-height: 32px !important;
    min-height: 32px !important;
}
a.disabled {
    color: gray; /* Change color to indicate it's disabled */
    pointer-events: none; /* Disable click events */
    text-decoration: none; /* Remove underline (optional) */
}
.play-label {
    position: relative;
    width: 100%;
}

.play-label .notif-number {
    position: absolute;
    right: 0;
    background-color: var(--yelow-500);
    border-radius: 50%;
    display: inline-block;
    padding: 5px 5px;
    color: #000;
    width: 25px;
    height: 25px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.transparent {
    background-color: transparent !important;
}
.text-white {
    color: #fff !important;
}

@media (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .event-card {
        width: 100% !important;
    }
}

.user-avatar {
    width: 48px;
    height: 48px;
}
