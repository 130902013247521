.input-select {
    width: calc(100% - 10px);
    background: #ffffff;
    border: 1px solid var(--gris-200);
    border-radius: 16px;
    height: -moz-fit-content;
    height: fit-content;
    align-items: center;
}

.input-select input {
    padding: 12px;
    background: #ffffff;
    border: 1px solid var(--gris-200);
    border-radius: 16px;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
}
.input-select.focused {
    outline: 1px solid var(--vert-500);
    border: 1px solid var(--vert-500);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}

.input-select .css-dczrug-control {
    border: none;
}
.input-select input {
    border: none;
}
.input-select input:focus,
.input-select input:hover {
    outline: none;
    border: none;
    box-shadow: none;
}
.css-dczrug-control:hover {
    border: none;
}
.css-18qp85y-control {
    border: none !important;
    box-shadow: none;
}
.css-18qp85y-control:hover {
    border: none !important;
    box-shadow: none;
}
.css-1ecm5ky-control {
    border: none !important;
    box-shadow: none !important;
}
.css-1dimb5e-singleValue .select-field-name {
    display: inline-block;
    padding-left: 10px;
}
.css-1or1wrc-control {
    background: transparent !important;
}
.black-theme .input-select {
    background: var(--opaced-bkg);
}
.black-theme .input-select input {
    background: transparent;
    color: #fff;
}

.black-theme .fabfab_select__control {
    background-color: transparent;
    color: #fff;
    border-radius: 16px;
}
.black-theme .fabfab_select__control--is-focused {
    background-color: var(--vert-500);
    color: #fff;
}
.black-theme .fabfab_select__control * {
    color: #fff;
}
.black-theme .fabfab_select__control * {
    color: #fff;
}
.black-theme .fabfab_select__menu {
    background-color: var(--dark);
    color: #fff;
}

.black-theme .fabfab_select__option:hover {
    background-color: var(--opaced-bkg);
}

.black-theme .fabfab_select__option--is-focused {
    background-color: var(--opaced-bkg);
}

.black-theme .fabfab_select__option--is-selected {
    background-color: var(--vert-600);
}
.black-theme .css-1dimb5e-singleValue {
    color: #fff;
}

.input-select .css-dczrug-control {
    border: none;
}
.input-select input {
    border: none;
}
.input-select input:focus,
.input-select input:hover {
    outline: none;
    border: none;
    box-shadow: none;
}
.css-dczrug-control:hover {
    border: none;
}
.css-18qp85y-control {
    border: none !important;
    box-shadow: none;
}
.css-18qp85y-control:hover {
    border: none !important;
    box-shadow: none;
}
.css-1ecm5ky-control {
    border: none !important;
    box-shadow: none !important;
}
.css-1dimb5e-singleValue .country-name {
    display: none;
}
.black-theme .input-select {
    background: var(--opaced-bkg);
}
.black-theme .input-select input {
    background: transparent;
    color: #fff;
}
.black-theme .fabfab_select__control {
    background-color: transparent;
    color: #fff;
}
.black-theme .fabfab_select__control--is-focused {
    background-color: var(--vert-500);
    color: #fff;
}
.black-theme .fabfab_select__control * {
    color: #fff;
}
.black-theme .fabfab_select__control * {
    color: #fff;
}
.black-theme .fabfab_select__menu {
    background-color: var(--dark);
    color: #fff;
}

.black-theme .fabfab_select__option:hover {
    background-color: var(--opaced-bkg);
}

.black-theme .fabfab_select__option--is-focused {
    background-color: var(--opaced-bkg);
}

.black-theme .fabfab_select__option--is-selected {
    background-color: var(--vert-600);
}
.black-theme .language__control {
    background-color: var(--dark);
    border-radius: 16px;
}
.black-theme .language__menu {
    background-color: var(--dark);
}
.input-select.icon-input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
}
.input-select.icon-input .fav-select {
    width: 100%;
}

.black-theme .input-select.icon-input .fabfab_select__control--is-focused {
    background-color: transparent !important;
}
.black-theme .input-select.icon-input.focused {
    background-color: var(--opaced-bkg);
}
.black-theme .input-select.icon-input .fabfab_select__value-container {
    padding: 0 !important;
}

.black-theme .input-select.icon-input .item-select {
    gap: 0 !important;
}
.black-theme .input-select.icon-input .item-select .flag {
    display: none !important;
}
.black-theme .input-select.icon-input .fabfab_select__control {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}
.black-theme .input-select.icon-input .fabfab_select__menu {
    width: 100% !important;
}
