.term-policy {
    display: none;
}
.term-policy.active {
    display: block;
}
.spinner-container {
    text-align: center;
    padding: 30px 0;
}
.spinner-container svg {
    height: 100px;
}
.term-policy-link {
    border-bottom: 2px solid #eee;
}
.term-policy-link h5 {
    color: #fff;
    padding: 10px 0;
    font-size: 15px;
    text-align: center;
}
.term-policy-link h5:hover {
    cursor: pointer;
}
.term-policy-link.active {
    border-bottom: 2px solid var(--vert-600);
}
.term-policy-link.active h5 {
    color: var(--vert-600);
}
.oups {
    text-align: center;
    margin-top: 50px;
    color: #eee;
    font-weight: 800;
}
.search-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.search-btn .input-field {
    width: calc(100% - 60px);
}
.search-btn .button {
    width: 50px;
    border-radius: none;
}
.search-btn svg {
    color: white;
}
