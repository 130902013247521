@font-face {
    font-family: 'Sora';
    src: url('Sora-Medium.eot');
    src: url('Sora-Medium.eot?#iefix') format('embedded-opentype'),
        url('Sora-Medium.woff2') format('woff2'),
        url('Sora-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sora';
    src: url('Sora-ExtraBold.eot');
    src: url('Sora-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Sora-ExtraBold.woff2') format('woff2'),
        url('Sora-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sora';
    src: url('Sora-ExtraLight.eot');
    src: url('Sora-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Sora-ExtraLight.woff2') format('woff2'),
        url('Sora-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sora';
    src: url('Sora-SemiBold.eot');
    src: url('Sora-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Sora-SemiBold.woff2') format('woff2'),
        url('Sora-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sora';
    src: url('Sora-Regular.eot');
    src: url('Sora-Regular.eot?#iefix') format('embedded-opentype'),
        url('Sora-Regular.woff2') format('woff2'),
        url('Sora-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sora';
    src: url('Sora-Thin.eot');
    src: url('Sora-Thin.eot?#iefix') format('embedded-opentype'),
        url('Sora-Thin.woff2') format('woff2'),
        url('Sora-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

