:root {
    --page-color-dark: #07130a;
    --page-color-light: ##ffffff;
    --white: #fff;
    --vert-50: #ecf8f0;
    --vert-100: #d9f2e0;
    --vert-200: #b3e5c1;
    --vert-300: #8dd8a2;
    --vert-400: #67cb83;
    --vert-500: #42be65;
    --vert-600: #349850;
    --vert-700: #27723c;
    --vert-800: #1a4c28;
    --vert-900: #0d2614;
    --vert-950: #07130a;
    --vert-opaced: rgba(66, 190, 101, 0.2);
    --gris-50: #f2f2f2;
    --gris-100: #e6e6e6;
    --gris-200: #cccccc;
    --gris-300: #b3b3b3;
    --gris-400: #999999;
    --gris-500: #212121;
    --gris-600: #666666;
    --gris-700: #4d4d4d;
    --gris-800: #333333;
    --gris-900: #1a1a1a;
    --gris-950: #0d0d0d;
    --yellow-100: #ffe1b3;
    --yelow-500: #ffaf33;
    --error-100: #f6bcbf;
    --error-500: #ca1c25;
    --error-500-rgb: 202, 28, 37;
    --error-800: #ca1c2555;
    --error-opaced: rgba(202, 28, 37, 0.2);
    --blue-500: #0f62fe;
    --orange-money: #ff7900;
    --opaced-text: rgba(255, 255, 255, 0.4);
    --opaced-bkg: rgba(255, 255, 255, 0.2);
    --linear-danger: linear-gradient(153.06deg, #ca1c25 -0.31%, #f31520 99.69%);
    --linear-orange: linear-gradient(153.06deg, #fe5858 -0.31%, #ee9617 99.69%);
    --linear-orange2: linear-gradient(153.06deg, #ffaf3355 -0.31%, #ffaf3366 99.69%);
    --linear-pink: linear-gradient(153.06deg, #ffc7c755 -0.31%, #ffc7c777 99.69%);
    --linear-gray: linear-gradient(153.06deg, #eee5 -0.31%, #eee7 99.69%);
    --font-family-sans-serif: 'Sora', sans-serif;
    --font-numeric: 'IBM Plex Mono', sans-serif;

    /* large device grid value */
    --grid-ld-width: 80px;
    --grid-ld-gap: 32px;
    /* medium device grid value */
    --grid-md-width: 72px;
    --grid-md-gap: 12px;
    /* small device grid value */
    --grid-sd-width: 72px;
    --grid-sd-gap: 24px;

    --light: #d8dbe0;
    --dark: #28292c;

    --primary-color: #121f4e;
    --secondary-color: #28a5ea;
    --tertiary-color: #16e2c5;

    --state-default: #7997ab;
    --state-success: #5dbe7f;
    --state-error: #f56060;
    --state-warning: #ffba00;
    --state-inactive: #ddd;

    --primary-background: #e5e5e5;
    --secondary-background: #fff;

    --primary-font: #04244d;
    --secondary-font: #526680;

    --time-cubit: 0.6s;
    --time-quarter: calc(var(--time-cubit) / 4);
    --time-half: calc(var(--time-cubit) / 2);
    --time-threequater: calc(var(--time-cubit) / 0.75);
    --time-single: var(--time-cubit);
    --time-double: calc(var(--time-cubit) * 2);
    --time-triple: calc(var(--time-cubit) * 3);
}

*,
::after,
::before {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    width: 100vw;
    height: 100vh;
}

.relative {
    position: relative !important;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: var(--font-family-sans-serif);
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    letter-spacing: -0.01em;
}

.page-dark {
    background-color: var(--page-color-dark);
    color: var(--white);
}

.page-light {
    background-color: var(--page-color-light);
}

[tabindex='-1']:focus:not(:focus-visible) {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 0;
    margin-block-start: 0;
    list-style: none;
    display: block;
    padding-inline-start: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    color: #0056b3;
}

a:not([href]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: none;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
    outline: none;
    border: none;
}

button.opaced:focus,
button.opaced:hover,
a.button.opaced:focus,
a.button.opaced:hover {
    background-color: var(--vert-500) !important;
}

input:focus,
input:hover {
    box-shadow: 0px 0px 4px #59c377;
}

button,
select {
    text-transform: none;
}

.error {
    color: var(--error-500) !important;
    background-color: var(--error-opaced) !important;
}

.success {
    color: var(--vert-500) !important;
    background-color: var(--vert-opaced) !important;
}
.text-success {
    color: var(--vert-500) !important;
}
select {
    word-wrap: normal;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
    -webkit-appearance: button;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}

input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='time'] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    line-height: 1.25;
}

.h1,
h1 {
    font-size: 48px;
    font-weight: 600;
}

.h2,
h2 {
    font-size: 2rem;
}

.h3,
h3 {
    font-size: 1.75rem;
}

.h4,
h4 {
    font-size: 16px;
    font-weight: 400;
}

.h5,
h5 {
    font-size: 16px;
    font-weight: 400;
}

.h6,
h6 {
    font-size: 16px;
    font-weight: 300;
    margin: 0;
}

/* 
     Layout & spacing
*/

.container {
    width: 100%;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
}

.full-page {
}

@media (min-width: 320px) {
    .container {
        width: calc(100vw - 48px);
    }

    .container.grid {
        max-width: calc(100vw - 48px);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 12px;
        grid-row-gap: 32px;
    }

    .container.grid-full {
        height: 100vh;
        max-width: 100vw;
        width: 100%;
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 12px;
        grid-row-gap: 32px;
    }

    .padding-section {
        padding-top: 48px;
        padding-bottom: 48px;
    }
}

@media (min-width: 764px) {
    /* Tab device Portrait
      48px for width
      12px for gap
   */
    .container {
        max-width: 708px;
    }

    .container.grid {
        max-width: 708px;
        display: grid;
        grid-template-columns: repeat(12, 48px);
        grid-template-rows: 1fr;
        grid-column-gap: 12px;
        grid-row-gap: 12px;
    }

    .container.grid-full {
        height: 100vh;
        max-width: 100vw;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 12px;
        grid-row-gap: 32px;
    }

    .padding-section {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    .h1,
    h1 {
        font-size: 32px;
        font-weight: 600;
    }

    .h2,
    h2 {
        font-size: 2rem;
    }

    .h3,
    h3 {
        font-size: 1.75rem;
    }

    .h4,
    h4 {
        font-size: 14px;
        font-weight: 400;
    }

    .h5,
    h5 {
        font-size: 14px;
        font-weight: 400;
    }

    .h6,
    h6 {
        font-size: 12px;
        font-weight: 300;
        margin: 0;
    }
}

@media (min-width: 912px) {
    /* landscape tablette device 
      56px for width
      16px for gap
   */
    .container {
        max-width: 848px;
    }

    .container.grid {
        max-width: 848px;
        display: grid;
        grid-template-columns: repeat(12, 56px);
        grid-template-rows: 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 56px;
    }

    .container.grid-full {
        height: 100vh;
        max-width: 100vw;
        height: 100vh;
        max-width: 100vw;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 32px;
    }

    .padding-section {
        padding-top: 64px;
        padding-bottom: 64px;
    }
}

@media (min-width: 1064px) {
    /* laptop device 
      64px for width
      24px for gap
   */
    .container {
        max-width: 1032px;
    }

    .container.grid {
        max-width: 1032px;
        display: grid;
        grid-template-columns: repeat(12, 64px);
        grid-template-rows: 1fr;
        grid-column-gap: 24px;
        grid-row-gap: 96px;
    }

    .container.grid-full {
        height: 100vh;
        max-width: 100vw;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 24px;
        grid-row-gap: 32px;
        padding: 16px;
    }

    .padding-section {
        padding-top: 96px;
        padding-bottom: 96px;
    }

    .h1,
    h1 {
        font-size: 48px;
        font-weight: 600;
    }

    .h2,
    h2 {
        font-size: 2rem;
    }

    .h3,
    h3 {
        font-size: 1.51rem;
    }

    .h4,
    h4 {
        font-size: 16px;
        font-weight: 400;
    }

    .h5,
    h5 {
        font-size: 14px;
        font-weight: 400;
    }

    .h6,
    h6 {
        font-size: 14px;
        font-weight: 300;
        margin: 0;
    }
}

.align-items-center,
.align-items-end,
.align-items-start {
    display: flex;
    width: 100%;
    height: 100%;
}

.align-item-center {
    align-items: center;
}

.align-item-end {
    align-items: end;
}

.align-item-start {
    align-items: start;
}

.section {
    position: relative;
    width: 100%;
    height: auto;
}

.section.flex {
    display: flex;
}

.span1 {
    grid-column: span 1;
}

.span2 {
    grid-column: span 2;
}

.span3 {
    grid-column: span 3;
}

.span4 {
    grid-column: span 4;
}

.span5 {
    grid-column: span 5;
}

.span6 {
    grid-column: span 6;
}

.span7 {
    grid-column: span 7;
}

.span8 {
    grid-column: span 8;
}

.span9 {
    grid-column: span 9;
}

.span10 {
    grid-column: span 10;
}

.span11 {
    grid-column: span 11;
}

.span12 {
    grid-column: span 12;
}

.space-between {
    justify-content: space-between;
}

/* 
     End layout & spacing
*/

/* 
     Global Style for buttons
*/

button,
a.button {
    font-size: 12px;
    font-weight: 300;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    border: none;
    border-radius: 16px;
}

button.small,
a.button.small {
    padding: 8px;
    border-radius: 12px;
}

button.style-link,
a.button.style-link {
    background: transparent;
    padding-left: 0;
}

.page-dark .style-link {
    color: var(--white);
}

button.default,
a.button.default {
    background: var(--vert-500);
    color: var(--white);
    justify-content: center;
}

button.orange,
a.button.orange {
    background: var(--linear-orange);
    color: var(--white);
}

button.danger,
a.button.danger {
    background: var(--linear-danger);
    color: var(--white);
}

button.danger:hover,
a.button.danger:hover {
    background: #b3050e;
    color: var(--white);
}

button.stretch {
    align-self: stretch;
}

.u {
    text-decoration: underline;
}

/* 
     End global Style for buttons
*/

/* All about Light and dark mode */

.opaced {
    background: rgba(255, 255, 255, 0.05) !important;
    color: white;
}

.backdropped {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(24px);
}

.livear {
    /* Livear 11 */

    background: linear-gradient(153.06deg, #cfd6e6 -0.31%, #e7eff9 99.69%);
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    color: var(--gris-800);
}

.check-item {
    margin: 12px 0;
}

/* checkbox-rect */
.checkbox-rect input[type='checkbox'] {
    display: none;
}

.checkbox-rect input[type='checkbox'] + label {
    display: block;
    position: relative;
    padding-left: 24px;
    margin-bottom: 20px;
    font-size: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.checkbox-rect input[type='checkbox']:hover + label:hover {
    color: var(--vert-500);
}

.checkbox-rect input[type='checkbox']:hover + label:before {
    background: #50565a;
    box-shadow: inset 0px 0px 0px 2px var(--vert-500);
}

.checkbox-rect input[type='checkbox'] + label:last-child {
    margin-bottom: 0;
}

.checkbox-rect input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 1.4em;
    height: 1.4em;
    border: 2px solid var(--gris-600);
    border-radius: 0.2em;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition:
        all 0.2s,
        background-color 0.2s ease-in-out;
    transition:
        all 0.2s,
        background-color 0.2s ease-in-out;
}

.checkbox-rect input[type='checkbox']:checked + label {
    color: var(--vert-500);
}

.checkbox-rect input[type='checkbox']:checked + label:before {
    width: 16px;
    height: 16px;
    border-radius: 0.2em;
    border: 2px solid var(--vert-500);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background: #50565a;
    box-shadow: 0 0 0 1px #000;
}

/* checkbox-rect end */

/* checkbox-circle */
.checkbox-circle input[type='radio'] {
    display: none;
}

.checkbox-circle input[type='radio'] + label {
    display: block;
    position: relative;
    padding-left: 24px;
    margin-bottom: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    color: var(--gris-400);
}

.checkbox-circle input[type='radio']:hover + label:hover {
    color: var(--vert-400);
}

.checkbox-circle input[type='radio']:hover + label:before {
    border: 1px solid #343a3f;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    background: var(--vert-500);
    box-shadow: 0 0 0 1px #000;
}

.checkbox-circle input[type='radio'] + label:last-child {
    margin-bottom: 0;
}

.checkbox-circle input[type='radio'] + label:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid var(--vert-500);
    border-radius: 1em;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition:
        all 0.2s,
        transform 0.3s ease-in-out;
    transition:
        all 0.2s,
        transform 0.3s ease-in-out;
    background: #f3f3f3;
}

.checkbox-circle input[type='radio']:checked + label {
    color: var(--vert-500);
}

.checkbox-circle input[type='radio']:checked + label:before {
    border-radius: 1em;
    border: 2px solid #fff;
    width: 16px;
    height: 16px;
    background: var(--vert-500);
    box-shadow: 0 0 0 1px #000;
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
    color: var(--vert-500);
}

/* checkbox-circle end */

/* checkbox-circle2 */
.checkbox-circle2 input[type='checkbox'] {
    display: none;
}

.checkbox-circle2 input[type='checkbox'] + label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    font:
        14px/20px 'Open Sans',
        Arial,
        sans-serif;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.checkbox-circle2 input[type='checkbox']:hover + label:hover {
    color: rgb(23, 86, 228);
}

.checkbox-circle2 input[type='checkbox']:hover + label:before {
    border: 1px solid #343a3f;
    box-shadow: 2px 1px 0 #343a3f;
}

.checkbox-circle2 input[type='checkbox'] + label:last-child {
    margin-bottom: 0;
}

.checkbox-circle2 input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid #343a3f;
    border-radius: 1em;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition:
        all 0.2s,
        background-color 0.2s ease-in-out;
    transition:
        all 0.2s,
        background-color 0.2s ease-in-out;
    background: #f3f3f3;
    box-shadow: -2px -1px 0 #343a3f;
}

.checkbox-circle2 input[type='checkbox']:checked + label:before {
    border-radius: 1em;
    border: 2px solid #fff;
    width: 1.15em;
    height: 1.15em;
    background: #50565a;
    box-shadow: 2px 1px 0 #50565a;
}

/* checkbox-circle2 end */

/* toggle-rect */
.toggle-rect input[type='checkbox'] {
    display: none;
}

.toggle-rect input[type='checkbox'] + label {
    display: block;
    position: relative;
    width: 3em;
    height: 1.6em;
    margin-bottom: 20px;
    border-radius: 0.2em;
    background: #f3f3f3;
    box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.toggle-rect input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 0.2em;
    background: #50565a;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0.2em;
    top: 0.2em;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.toggle-rect input[type='checkbox']:checked + label {
    background: #fff;
}

.toggle-rect input[type='checkbox']:checked + label:before {
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
    left: 1.6em;
}

/* toggle-rect end*/

/* toggle-rect-bw */
.toggle-rect-bw input[type='checkbox'] {
    display: none;
}

.toggle-rect-bw input[type='checkbox'] + label {
    display: block;
    position: relative;
    width: 3em;
    height: 1.6em;
    margin-bottom: 20px;
    background: #50565a;
    border-radius: 0.2em;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.toggle-rect-bw input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 0.2em;
    background: #f7f2f2;
    position: absolute;
    left: 0.2em;
    top: 0.2em;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.toggle-rect-bw input[type='checkbox']:checked + label {
    background: #40464a;
}

.toggle-rect-bw input[type='checkbox']:checked + label:before {
    left: 1.6em;
}

/* toggle-rect-bw end*/

/* toggle-rect-color */
.toggle-rect-color input[type='checkbox'] {
    display: none;
}

.toggle-rect-color input[type='checkbox'] + label {
    display: block;
    position: relative;
    width: 3em;
    height: 1.6em;
    margin-bottom: 20px;
    background: #e84d4d;
    border-radius: 0.2em;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.toggle-rect-color input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 0.2em;
    background: #50565a;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0.2em;
    top: 0.2em;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.toggle-rect-color input[type='checkbox']:checked + label {
    background: #47cf73;
}

.toggle-rect-color input[type='checkbox']:checked + label:before {
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
    left: 1.6em;
}

/* toggle-rect-color end*/

/* toggle-rect-color */
.toggle-rect-dark input[type='checkbox'] {
    display: none;
}

.toggle-rect-dark input[type='checkbox'] + label {
    display: block;
    position: relative;
    width: 3em;
    height: 1.6em;
    margin-bottom: 20px;
    background: #303e58;
    border-radius: 0.2em;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.toggle-rect-dark input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 0.2em;
    background: #e84d4d;
    position: absolute;
    left: 0.2em;
    top: 0.2em;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.toggle-rect-dark input[type='checkbox']:checked + label:before {
    background: #47cf73;
    left: 1.6em;
}

/* toggle-rect-dark end*/

/* toggle-pill */
.toggle-pill input[type='checkbox'] {
    display: none;
}

.toggle-pill input[type='checkbox'] + label {
    display: block;
    position: relative;
    width: 3em;
    height: 1.6em;
    margin-bottom: 20px;
    border-radius: 1em;
    background: #f3f3f3;
    box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-transition: background-color 0.1s ease-in-out;
    transition: background-color 0.1s ease-in-out;
}

.toggle-pill input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 1em;
    background: #50565a;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0.2em;
    top: 0.2em;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.toggle-pill input[type='checkbox']:checked + label {
    background: #fff;
}

.toggle-pill input[type='checkbox']:checked + label:before {
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
    left: 1.6em;
}

/* toggle-pill end */

/* toggle-pill-bw */
.toggle-pill-bw input[type='checkbox'] {
    display: none;
}

.toggle-pill-bw input[type='checkbox'] + label {
    display: block;
    position: relative;
    width: 3em;
    height: 1.6em;
    margin-bottom: 20px;
    background: #50565a;
    border-radius: 1em;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-transition: background-color 0.1s ease-in-out;
    transition: background-color 0.1s ease-in-out;
}

.toggle-pill-bw input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 1em;
    background: #f7f2f2;
    position: absolute;
    left: 0.2em;
    top: 0.2em;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.toggle-pill-bw input[type='checkbox']:checked + label {
    background: #40464a;
}

.toggle-pill-bw input[type='checkbox']:checked + label:before {
    left: 1.6em;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* toggle-pill-bw end */

/* toggle-pill-color */
.toggle-pill-color input[type='checkbox'] {
    display: none;
}

.toggle-pill-color input[type='checkbox'] + label {
    display: block;
    position: relative;
    width: 3em;
    height: 1.6em;
    margin-bottom: 20px;
    border-radius: 1em;
    background: #e84d4d;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-transition: background-color 0.1s ease-in-out;
    transition: background-color 0.1s ease-in-out;
}

.toggle-pill-color input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 1em;
    background: #fff;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0.2em;
    top: 0.2em;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.toggle-pill-color input[type='checkbox']:checked + label {
    background: #47cf73;
}

.toggle-pill-color input[type='checkbox']:checked + label:before {
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
    left: 1.6em;
}

/* toggle-pill-color end */

/* toggle-pill-color */
.toggle-pill-dark input[type='checkbox'] {
    display: none;
}

.toggle-pill-dark input[type='checkbox'] + label {
    display: block;
    position: relative;
    width: 3em;
    height: 1.6em;
    margin-bottom: 20px;
    border-radius: 1em;
    background: #303e58;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-transition: background-color 0.1s ease-in-out;
    transition: background-color 0.1s ease-in-out;
}

.toggle-pill-dark input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 1em;
    background: #e84d4d;
    position: absolute;
    left: 0.2em;
    top: 0.2em;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.toggle-pill-dark input[type='checkbox']:checked + label:before {
    background: #47cf73;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
    left: 1.6em;
    -webkit-transform: rotate(295deg);
    transform: rotate(295deg);
}

/* toggle-pill-dark end */

/*the container must be positioned relative:*/

.select-input {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    border: 1px solid var(--opaced-bkg);
    padding: 12px;
    border-radius: 16px;
    width: 100%;
    display: flex;
    gap: 12px;
}

.select-side {
    position: relative;
    flex: 2;
}

.select-side select {
    display: none;
    /*hide original SELECT element:*/
}

.select-selected {
    background-color: transparent;
}

/*style the arrow inside the select element:*/
.select-selected:after {
    position: absolute;
    content: '';
    top: 4px;
    right: 0;
    width: 12px;
    height: 12px;
    background-image: url(../icons/important/select-icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
    color: #ffffff;
    padding: 0;
    border: 1px solid transparent;
    /* border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent; */
    cursor: pointer;
    user-select: none;
}

.select-items div {
    padding: 12px;
    border-radius: 16px;
    margin-bottom: 8px;
}

.select-items div:last-child {
    margin-bottom: 0px;
}

.select-selected div {
}

/*style items (options):*/
.select-items {
    position: absolute;
    /* background-color: DodgerBlue; */
    top: calc(100% + 24px);
    left: -8px;
    right: 0;
    z-index: 99;
    padding: 8px;
    border-radius: 16px;
    height: 200px;
    overflow: auto;
}

/*hide the items when the select box is closed:*/
.select-hide {
    display: none;
}

.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

/* 
    Date time pickers
 */

.date-time-pikers {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
}

.date-time-pikers .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 12px;
    padding: 12px;
    border: 1px solid var(--opaced-bkg);
    width: fit-content;
    border-radius: 16px;
    flex: 1;
}

.date-time-pikers .item .dt-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    position: relative;
    width: fit-content;
    max-width: 100px;
    border-radius: 16px;
}

.date-time-pikers .item .dt-input span {
    font-size: 12px;
    line-height: 160%;
    /* or 19px */
    letter-spacing: -0.01em;
    color: rgba(255, 255, 255, 0.56);
}

.date-time-pikers .item .dt-input input {
    border: none;
    background: transparent;
    padding: 0;
    color: white;
}

/* Surcharge sur les propriete venant du plugin */
.the-datepicker__deselect .the-datepicker__deselect-button {
    position: absolute;
    text-decoration: none;
    color: var(--vert-500);
    font-weight: bold;
}

.date-time-pikers .item .dt-input span {
    right: -4px;
}

.dt-input .select-input {
}

.dt-input .select-input {
    padding: 0;
    border: none;
}

.dt-input .select-input .select-side .select-selected {
    color: #ffffff;
    padding: 0;
}

.flex-none {
    flex: none;
}

.d-flex {
    display: flex;
}
.d-inline-block {
    display: inline-block;
}
.gap-1 {
    gap: 5px;
}
.gap-0 {
    gap: 0 !important;
}
.justify-content-center {
    justify-content: center !important;
}

.align-items-center {
    align-items: center !important;
}
.text-center {
    text-align: center !important;
}

.flex-column {
    flex-direction: column !important;
}
.flex-row {
    flex-direction: row !important;
}
.text-underline {
    text-decoration: underline !important;
}
.text-danger {
    color: var(--error-500) !important;
}
a.text-danger {
    color: var(--error-500) !important;
}
.text-strong {
    font-weight: bold !important;
}
.text-strong {
    font-weight: bold !important;
}
.w-100 {
    width: 100% !important;
}
.w-50 {
    width: 50% !important;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.mt-auto {
    margin-top: auto !important;
}

.me-0 {
    margin-right: 0 !important;
}

.me-1 {
    margin-right: 0.25rem !important;
}

.me-2 {
    margin-right: 0.5rem !important;
}

.me-3 {
    margin-right: 1rem !important;
}

.me-4 {
    margin-right: 1.5rem !important;
}

.me-5 {
    margin-right: 3rem !important;
}

.me-auto {
    margin-right: auto !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ms-0 {
    margin-left: 0 !important;
}

.ms-1 {
    margin-left: 0.25rem !important;
}

.ms-2 {
    margin-left: 0.5rem !important;
}

.ms-3 {
    margin-left: 1rem !important;
}

.ms-4 {
    margin-left: 1.5rem !important;
}

.ms-5 {
    margin-left: 3rem !important;
}

.ms-auto {
    margin-left: auto !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}

.px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pt-5 {
    padding-top: 3rem !important;
}

.pe-0 {
    padding-right: 0 !important;
}

.pe-1 {
    padding-right: 0.25rem !important;
}

.pe-2 {
    padding-right: 0.5rem !important;
}

.pe-3 {
    padding-right: 1rem !important;
}

.pe-4 {
    padding-right: 1.5rem !important;
}

.pe-5 {
    padding-right: 3rem !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

.pb-5 {
    padding-bottom: 3rem !important;
}

.ps-0 {
    padding-left: 0 !important;
}

.ps-1 {
    padding-left: 0.25rem !important;
}

.ps-2 {
    padding-left: 0.5rem !important;
}

.ps-3 {
    padding-left: 1rem !important;
}

.ps-4 {
    padding-left: 1.5rem !important;
}

.ps-5 {
    padding-left: 3rem !important;
}

.gap-0 {
    gap: 0 !important;
}

.gap-1 {
    gap: 0.25rem !important;
}

.gap-2 {
    gap: 0.5rem !important;
}

.gap-3 {
    gap: 1rem !important;
}

.gap-4 {
    gap: 1.5rem !important;
}

.gap-5 {
    gap: 3rem !important;
}
.transparent {
    background-color: transparent !important;
}
.border-radius-0 {
    border-radius: 0 !important;
}
.border-color-white {
    border-color: white;
}
.border-1 {
    border: 1px solid white;
}
.border-right-1 {
    border-right: 1px solid white;
}

.text-primary {
    color: var(--vert-600) !important;
}
.warning {
    background-color: var(--yelow-500);
}
.text-gray {
    color: var(--gris-400) !important;
}
