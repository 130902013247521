.tournament-player-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 15px 0;
    border-top: 1px solid var(--opaced-bkg);
}

.tournament-details .tournament-current-user {
    padding-bottom: 10px;
}
.tournament-player-infos:last-child {
    border-bottom: none !important;
}

.player-pseudo {
    display: flex;
    gap: 10px;
    align-items: center;
}
.tournament-current-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tournament-player-infos img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}
