.referal-user-container {
    justify-content: center;
    align-items: flex-start;
    padding: 5px 5px 7px 10px;
    margin-bottom: 5px;
}

.referal-user-container .row {
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 1px 0px;
}
