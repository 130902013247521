:root {
    --toast-success-hue: 152;
    --toast-error-hue: 0;
    --toast-warning-hue: 44;
    --toast-info-hue: 206;
    --toast-padding: 1.5em;
    --toast-margin: 0.5em;
    --toast-bg-color-fallback: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
        rgba(255, 255, 255, 0.15);
    --toast-text-color-fallback: hsl(var(--secondary-hue) 10% 25%);
    --toast-opacity: 0.99;
    --toast-content-gap: 16px;
    --toast-width: 230px;
    --toast-bg-color-success: linear-gradient(180deg, hsla(226, 93%, 63%, 0.2) 0%, rgba(37, 235, 103, 0) 100%),
        rgba(255, 255, 255, 0.15);
    --a: hsl(0, 94%, 50%);
}

.toast {
    background-color: var(--toast-bg-color-fallback);
    padding: var(--toast-padding);
    box-shadow:
        hsl(var(--secondary-hue) 10% 10% / 10%) 0px 1px 3px 0px,
        hsl(var(--secondary-hue) 10% 10% / 5%) 0px 1px 2px 0px;
    border-radius: 0.5em;
    position: relative;
    color: var(--toast-text-color-fallback);
    opacity: var(--toast-opacity);
    z-index: 99999;
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    border-radius: 24px;
    width: var(--toast-width);
}

.toast:hover {
    opacity: 1;
    box-shadow:
        hsl(var(--secondary-hue) 10% 10% / 30%) 0px 1px 3px 0px,
        hsl(var(--secondary-hue) 10% 10% / 15%) 0px 1px 2px 0px;
}

.toast + .toast {
    margin-top: var(--toast-margin);
}

.toast-message {
    display: flex;
    gap: var(--toast-content-gap);
    align-items: top;
}

.toast-close-btn {
    position: absolute;
    top: 0.5em;
    right: 1em;
    padding: 0;
    line-height: 1;
    height: 1em;
    width: 1em;
    background: none;
    border: none;
    cursor: pointer;
    color: inherit;
    opacity: 0.75;
}
.toast--success {
    color: #fff;
    border: 1px solid hsl(var(--toast-success-hue) 90% 51%);
    background-color: hsla(var(--toast-success-hue), 90%, 51%, 0.2);
}

.page-light .toast--success {
    color: #fff;
    border: 1px solid hsl(var(--toast-success-hue) 90% 51%);
    background-color: hsla(var(--toast-success-hue), 90%, 57%, 1);
}

.toast--success .icon--thumb {
    color: hsl(var(--toast-success-hue) 90% 51%);
    fill: hsl(var(--toast-success-hue) 90% 51%);
}

.toast--error {
    color: #fff;
    background-color: hsla(var(--toast-error-hue), 100%, 53%, 0.2);
    border: 1px solid hsl(var(--toast-error-hue) 100% 53%);
}

.toast--error .icon--thumb {
    color: hsl(var(--toast-error-hue) 100% 53%);
    fill: hsl(var(--toast-error-hue) 100% 53%);
}
.page-light .toast--error {
    color: #fff;
    background-color: hsla(var(--toast-error-hue), 100%, 53%, 1);
    border: 1px solid hsl(var(--toast-error-hue) 100% 53%);
}

.toast--info {
    color: #fff;
    background-color: hsla(var(--toast-info-hue), 66%, 79%, 0.2);
    border: 1px solid hsl(var(--toast-info-hue) 66% 79%);
}

.toast--info .icon--thumb {
    color: hsl(var(--toast-info-hue) 66% 79%);
    fill: hsl(var(--toast-info-hue) 66% 79%);
}

.toast--warning {
    color: #fff;
    background-color: hsla(var(--toast-warning-hue), 98%, 50%, 0.2);
    border: 1px solid hsl(var(--toast-warning-hue) 98% 50%);
}

.toast--warning .icon--thumb {
    color: hsl(var(--toast-warning-hue) 98% 51%);
    fill: hsl(var(--toast-warning-hue) 98% 51%);
}
