.game-link {
    display: inline-block;
    padding: 10px 20px;
    padding-right: 20px;
    width: 50%;
}
.game-link:hover {
    cursor: pointer;
}
.game-link h5 {
}
.game-link.active {
    border-bottom: 3px solid var(--vert-500);
}
.game-link:not(.active) {
    border-bottom: 3px solid var(--vert-800);
}
.games-body:not(.active) {
    display: none;
}
.list-page-content {
    width: 100%;
    margin: 0;
    overflow-x: hidden;
}

.list-page-content .page-intro {
    margin-top: 20px;
}
@media (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .game-link h3 {
        font-size: 14px;
    }
    .games-body,
    .game-links {
        padding-left: 0;
        padding-right: 0;
    }

    .games-list {
        margin-top: 30px;
    }
}

.list-grid {
    margin-top: 20px !important;
}
