.input-field strong.invalid {
    color: var(--error-500);
}
.input-field .icon-input.invalid {
    border-color: var(--error-500);
    outline: 1px solid var(--error-500);
}
.input-field .icon-input.invalid.focused {
    outline: 1px solid var(--error-500);
    box-shadow: 0px 0px 4px var(--error-500);
}
.input-field .icon-input input {
    color: white;
    background: transparent;
}
.input-field .icon-input input:hover,
.input-field .icon-input input:focus {
    border: none;
    outline: none;
    color: white;
    box-shadow: none;
}

.icon-input.focused,
.icon-input:hover {
    box-shadow: 0px 0px 4px #59c377;
}

.icon-input.focused {
    outline: 1px solid var(--vert-500);
    border: 1px solid var(--vert-500);
    -webkit-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -moz-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    -ms-transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
    transition: all 0.1s cubic-bezier(0.61, 0.25, 0.14, 0.53);
}
