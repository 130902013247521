.awaiting-game-icon {
    width: 30px !important;
    height: 30px !important;
}
.awaiting-game-icon svg {
    width: 30px !important;
    height: 30px !important;
}
.awaiting-g-bet {
    font-size: 14px;
}
.creator-infos {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}
.creator-infos .user-avatar {
    height: 30px;
    width: 30px;
}
.creator-infos .user-avatar img {
    border-radius: 100%;
}
