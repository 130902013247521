.history-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 5px;
    margin: 15px 0;
}
.history-table td {
    width: 33.33%;
    color: #fff;
    padding: 5px 20px;
    font-size: 13px;
    background-color: rgba(66, 190, 101, 0.05);
}

.history-table tr td:last-child {
    text-align: right;
}
.history-table tr td:first-child {
    text-align: left;
}
.history-table tr td {
    position: relative;
}
.history-table tr td:first-child::after {
    content: '';
    position: absolute;
    width: 5px;
    height: 90%;
    background-color: var(--error-500);
    opacity: 0.3;
    border-radius: 5px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.history-table tr.msg-success td:first-child::after {
    background-color: var(--vert-500);
}

.history-table tr:hover td {
    background-color: rgba(66, 190, 101, 0.2);
}

.history-table td p {
    padding: 0;
    margin: 0;
}
.history-table tr {
    padding: 10px;
}
.history-table tr:hover {
    cursor: pointer;
}
.history-table tr:not(:last-child) td {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

@media (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .history-list {
        margin-top: 40px;
    }
}
